<template>
  <div>
    <div class="d-flex justify-space-between px-10">
      <button
        class="btn_x text_white"
        onclick="
          window.history.go(-1);
          return false;
        "
      >
        &#8592;
      </button>
      <router-link
        :to="{ name: ROUTES_HOME.HOME.NAME, query: {} }"
        class="btn_x text_white"
      >
        x
      </router-link>
    </div>
    <div class="video_au">
      <video controls autoplay loop muted width="1200" height="800">
        <source :src="$t('home.guide_video')" />
      </video>
    </div>
  </div>
</template>

<script>
import { ROUTES_HOME } from '@/core/configs/configRoutes';

export default {
  name: 'GuideVideo',
  data() {
    return { ROUTES_HOME: ROUTES_HOME };
  }
};
</script>

<style lang="scss" scoped>
.video_au {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .video_au {
    width: 100%;
  }

  video {
    width: 100%;
  }
}
</style>
