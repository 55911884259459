<template>
  <mew-stepper
    :items="stepperItems"
    :on-step="step"
    style="width: 100%"
    class="access__wallet__mobile"
  >
    <!--
    =====================================================================================
      Step 1: Verify Keystore Files
    =====================================================================================
    -->
    <template v-if="step === 1" #stepperContent1>
      <div class="d-flex align-center justify-space-between ">
        <div class="step3_create">
          <div class="font-weight-bold text_primary step_title">{{ $t('home.step_one') }}</div>
          <div class=" font-weight-bold create_step__title">
            {{ $t('home.select_your_keystore_file') }}
          </div>
          <p class="text_des">
            {{ $t('home.access-select') }}
          </p>
          <mew-button
            :title="selectFile"
            btn-style="outline"
            color-theme="primary"
            btn-size="xlarge"
            :has-full-width="true"
            :shows-active-state="true"
            @click.native="uploadBtn"
            class="btn_select mt-4 btn_width"
          />
          <input
            ref="jsonInput"
            type="file"
            name="file"
            style="display: none"
            @change="uploadFile"
          />
        </div>

        <div class="d-flex align-start img_right">
          <img
            src="@/assets/images/create/keystore.png"
            alt="Keystore File"
            width="100%"
          />
        </div>
      </div>
    </template>
    <!--
    =====================================================================================
      Step 2: Enter Password
    =====================================================================================
    -->
    <template v-if="step === 2" #stepperContent2>
      <div class="border-radius--10px">
        <div class="font-weight-bold text_primary step_title">{{ $t('home.step_two') }}</div>
        <div class=" font-weight-bold  create_step__title">{{ $t('home.enter_password') }}</div>
        <div class="text_des">{{ $t('home.enter_your_password_to_unlock') }}</div>
        <form @submit.prevent="unlockBtn">
          <v-row class="align-stretch">
            <v-col cols="12" class="px-0 py-0">
              <mew-input
                v-model="password"
                :label="label"
                :placeholder="placeholder"
                type="password"
              />
            </v-col>
            <v-col cols="12" class="px-0 py-0 access_wallet__steptwo">
              <app-btn-row
                v-if="!isUnlockingKeystore"
                class="pb-5 access_wallet_btn"
                :next-btn-text="accessWallet"
                :next-btn-method="unlockBtn"
                :back-btn-method="backStepOne"
                :next-disable="!password"
              />
              <!--
            =====================================================================================
              Unlocking State: isUnlockingKeystore = true
            =====================================================================================
            -->
              <v-row v-else justify="center" align="center" class="pt-5 pb-9">
                <v-progress-circular
                  indeterminate
                  color="greenPrimary"
                ></v-progress-circular>
                <p class="mb-0 mx-3">
                  {{$t('home.loading_access_wallet')}}
                </p>
              </v-row>
            </v-col>
          </v-row>
        </form>
      </div>
    </template>
  </mew-stepper>
</template>

<script>
import { Toast, ERROR } from '@/modules/toast/handler/handlerToast';
import AppBtnRow from '@/core/components/AppBtnRow';

export default {
  name: 'AccessWalletKeystore',
  components: {
    AppBtnRow
  },
  props: {
    handlerAccessWallet: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      label: this.$t('home.enter_password'),
      placeholder: this.$t('home.enter_keystore_password'),
      selectFile: this.$t('home.select_file'),
      accessWallet: this.$t('home.access_wallet'),
      step: 1,
      file: {},
      password: '',
      stepperItems: [
        {
          step: 1
        },
        {
          step: 2
        }
      ],
      isUnlockingKeystore: false
    };
  },

  methods: {
    /**
     * Sets keystore file and directs to step 2
     */
    uploadFile(e) {
      const reader = new FileReader();
      reader.onloadend = evt => {
        try {
          this.file = JSON.parse(evt.target.result);
          this.step = 2;
        } catch (e) {
          Toast(e.message, {}, ERROR);
        }
      };
      reader.readAsBinaryString(e.target.files[0]);
    },
    /**
     * Opens file input control
     */
    uploadBtn() {
      const jsonInput = this.$refs.jsonInput;
      jsonInput.value = '';
      jsonInput.click();
    },
    /**
     * Unlocks Keystre wallet file
     * Sets isUnlockingKeystore = true , to dispaly loading state
     * On Error - dispatches Toast on error and sets isUnlockingKeystore = false
     */
    unlockBtn() {
      this.isUnlockingKeystore = true;
      this.handlerAccessWallet
        .unlockKeystoreWallet(this.file, this.password)
        .then(res => {
          if (res) {
            this.$emit('unlock');
            this.isUnlockingKeystore = false;
          }
        })
        .catch(e => {
          this.isUnlockingKeystore = false;
          Toast(e.message, {}, ERROR);
        });
    },
    /**
     * Methods changes stepper to step 1
     * Used in STEP 2
     */
    backStepOne() {
      this.step = 1;
    }
  }
};
</script>
<style lang="scss" scoped>

.btn_width{
  width: 65% !important;
}
@media (max-width: 768px) {
  .btn_width{
  width: 100% !important;
}
}
</style>
