import { render, staticRenderFns } from "./TheBuyHardwareWalletLayout.vue?vue&type=template&id=67fd9151&scoped=true&"
import script from "./TheBuyHardwareWalletLayout.vue?vue&type=script&lang=js&"
export * from "./TheBuyHardwareWalletLayout.vue?vue&type=script&lang=js&"
import style0 from "./TheBuyHardwareWalletLayout.vue?vue&type=style&index=0&id=67fd9151&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67fd9151",
  null
  
)

export default component.exports