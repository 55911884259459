<template>
<div>
  <!-- <button
    class="btn_x"
    @click="
      $router.push({
        path: '/wallet/dashboard'
      })
    "
  >x</button> -->
  <the-wrapper-wallet :total-left-col-items="1" :total-right-col-items="2">
    <template #leftColItem1>
      <module-send />
    </template>
    <template #rightColItem1>
      <module-tokens-value />
    </template>
    <template v-if="hasHistory" #rightColItem2>
      <module-transfer-history />
    </template>
  </the-wrapper-wallet>
</div>
</template>

<script>
import ModuleSend from '@/modules/send/ModuleSend';
import TheWrapperWallet from '@/core/components/TheWrapperWallet';
// import ModuleTokensValue from '@/modules/balance/ModuleTokensValue';
// import ModuleTransferHistory from '@/modules/transfer-history/ModuleTransferHistory';
import { mapGetters } from 'vuex';

export default {
  components: {
    ModuleSend,
    TheWrapperWallet,
    // ModuleTokensValue,
    // ModuleTransferHistory,
  },
  computed: {
    ...mapGetters('notifications', ['txNotifications']),
    hasHistory() {
      return this.txNotifications && this.txNotifications.length > 0;
    }
  }
};
</script>
<style lang="scss" scoped>
.btn_x {
  position: absolute;
  top: -1%;
  left: 75%;
  transform: translate(-50%, -50%);
  color: #ccc;
  font-size: 35px;
  z-index: 999;
}
</style>
