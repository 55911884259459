<template>
  <div class="d-flex align-center justify-space-between mt-5 access_wallet">
    <!-- <div class="d-flex align-start img_right mg-right"> 
      <img
        src="@/assets/images/create/iouiouiouio.png"
        alt="Keystore File"
        width="100%"
      />
    </div> -->

    <div class="step3_create">
      <h3 class="mb-6 create_step__title">{{$t('accessWallet.enter_private_key')}}</h3>
      <!--
    =====================================================================================
      Private Key Input
    =====================================================================================
    -->
      <mew-input
        v-model="privateKey"
        :label="lablePrivateKey"
        class="text-white"
        :placeholder="placeholder"
        :rules="privKeyRulles"
        type="password"
      />
      <!--
    =====================================================================================
      Terms Checkbox & Access Button
    =====================================================================================
    -->
      <div class="text-center access_wallet_private">
        <mew-checkbox
          v-model="acceptTerms"
          :label="label"
          :link="link"
          class="justify-center access_checkbox"
        />
        <v-row
          dense
          class="align-center justify-center pt-4 access_private__btn"
        >
          <v-col cols="10" sm="6">
            <mew-button
              has-full-width
              :title="title"
              btn-size="xlarge"
              :disabled="!disableBtn"
              @click.native="unlockBtn"
              class="btn_select"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { isPrivateKey } from '../handlers/helpers';
import { isValidPrivate } from 'ethereumjs-util';
import {
  getBufferFromHex,
  sanitizeHex
} from '../../../access-wallet/common/helpers';
import { isString } from 'lodash';
export default {
  name: 'AccessWalletPrivateKey',
  props: {
    handlerAccessWallet: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      label: this.$t('accessWallet.accept-terms'),
      lablePrivateKey: this.$t('accessWallet.private_key'),
      placeholder: this.$t('accessWallet.enter_private_key'),
      title: this.$t('common.wallet.access'),
      privateKey: '',
      acceptTerms: false,
      link: {
        title: this.$t('accessWallet.terms'),
        url: ''
      }
    };
  },
  computed: {
    /**
     * Property that controls Access Wallet button
     * Button is enabled when terms were accepted and
     * private key entered is valid
     * @return boolean
     */
    disableBtn() {
      return this.acceptTerms && this.isPrivKey;
    },
    /**
     * Property validates whether or not entered private key is valid
     * @return booelan
     */
    isPrivKey() {
      const _privKey = Buffer.isBuffer(this.actualPrivateKey)
        ? this.actualPrivateKey
        : getBufferFromHex(sanitizeHex(this.actualPrivateKey));
      return isPrivateKey(this.privateKey) && isValidPrivate(_privKey);
    },
    /**
     * @returns actual private without '0x' prefix
     */
    actualPrivateKey() {
      if (!isString(this.privateKey)) return '';
      return this.privateKey && this.privateKey.substr(0, 2) === '0x'
        ? this.privateKey.replace('0x', '')
        : this.privateKey;
    },
    /**
     * @returns rulles fot the private key input
     */
    privKeyRulles() {
      return [
        value => !!value || 'Required',
        value => isPrivateKey(value) || 'This is not a real private Key'
      ];
    }
  },
  methods: {
    /**
     * Method unlocks private key wallet,
     * Emits to the parent module to enter wallet route
     */
    unlockBtn() {
      this.handlerAccessWallet.unlockPrivateKeyWallet(this.actualPrivateKey);
      this.$emit('unlock');
      this.privateKey = '';
    }
  }
};
</script>
<style lang="scss" scoped>
.mg-right{
  margin-right: 100px;
}
.access_wallet {
  width: 100% ;
}

@media(max-width: 768px){
  .mg-right{
  margin-right: 0px;
}

}
</style>