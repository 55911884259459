<template>
  <!--
  =============================================================
  Module Tokens
  =============================================================
  -->
  <div class="module-tokens my_token_value mytoken">
    <!-- <v-skeleton-loader v-if="loading && tokensData" class="mx-auto" type="table" /> -->
    <!-- <mew-module
      v-if="!loading && tokensData.length > 0 && !dense"
      :subtitle="subtitle"
      :has-body-padding="false"
      :has-header-padding="false"
      :title="totalTokensValue"
      class="my_token_value au_my_token_value au_token_custom"
    > -->
    <mew-module
      v-if="!loading && tokensData.length > 0 && !dense"
      :has-body-padding="false"
      :has-header-padding="false"
      class="my_token_value au_my_token_value au_token_custom"
    >
      <template #rightHeaderContainer>
        <div class="pb-3">
          <span
            v-if="!hasCustom"
            class="cursor-pointer pl-3 custom_token"
            @click="toggleAddCustomToken"
            >+ {{ $t('common.custom_token') }}</span
          >
          <mew-menu
            v-else
            activator-text-color="greenPrimary--text"
            :list-obj="menuObj"
            @goToPage="customTokenAction"
          />
        </div>
      </template>
      <template #moduleBody class="au-body-token">
        <div>
          <mew-table
            :has-color="false"
            :table-headers="tableHeaders"
            :table-data="tokensData"
            class="table_tokens"
          />
        </div>
      </template>
    </mew-module>
    <mew-table
      v-if="!loading && tokensData.length > 0 && dense"
      :has-color="false"
      :table-headers="tableHeaders"
      :table-data="tokensData"
    />
    <!--
    =====================================================================================
      display if the user has no tokens
    =====================================================================================
    -->
    <balance-empty-block
      v-if="!loading && tokensData.length === 0"
      is-tokens
      :is-eth="isEthNetwork"
    />
    <!--
    =====================================================================================
      add Custom Token form
    =====================================================================================
    -->
    <token-add-custom
      :close="toggleAddCustomToken"
      :open="openAddCustomToken"
    />
    <!--
    =====================================================================================
      delete Custom Token form
    =====================================================================================
    -->
    <token-delete-custom
      :close="toggleDeleteCustomToken"
      :open="openDeleteCustomToken"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import BalanceEmptyBlock from './components/BalanceEmptyBlock';
import TokenAddCustom from './components/TokenAddCustom';
import TokenDeleteCustom from './components/TokenDeleteCustom';
import { ROUTES_WALLET } from '@/core/configs/configRoutes';
import { Toast, INFO, WARNING } from '@/modules/toast/handler/handlerToast';
import { uniqWith, isEqual } from 'lodash';
import { currencyToNumber } from '@/core/helpers/localization';
import { MAIN_TOKENS } from '@/core/configs/defaultTokens';
import { compareAddress } from '@/core/helpers/common';

export default {
  components: {
    BalanceEmptyBlock,
    TokenDeleteCustom,
    TokenAddCustom
  },
  props: {
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      subtitle: this.$t('common.my_tokens_value'),
      openAddCustomToken: false,
      openDeleteCustomToken: false,
      tableHeaders: [
        {
          text: this.$t('interface.tokens.token'),
          value: 'token',
          sortable: false,
          width: '20%'
        },
        // {
        //   text: this.$t('interface.tokens.type'),
        //   value: 'callToAction',
        //   sortable: false,
        //   width: '20%'
        // },
        // {
        //   text: 'Price',
        //   value: 'price',
        //   sortable: false,
        //   width: '20%'
        // },
        // {
        //   text: 'Market Cap',
        //   value: 'cap',
        //   sortable: false,
        //   width: '20%'
        // },
        // {
        //   text: '24H',
        //   value: 'change',
        //   sortable: false,
        //   width: '20%'
        // },
        {
          text: this.$t('interface.tokens.balance'),
          value: 'balance',
          sortable: false,
          width: '20%'
        },
        {
          text: this.$t('interface.tokens.lock'),
          value: 'lockAmount',
          sortable: false,
          width: '20%'
        },
        {
          text: this.hasSwap ? '' : this.$t('interface.tokens.type'),
          value: 'callToAction',
          sortable: false,
          width: '15%'
        }
      ],
      menuObj: {
        name: this.$t('interface.tokens.custom'),
        items: [
          {
            items: [
              {
                title: this.$t('interface.tokens.add_token'),
                to: 'add',
                iconName: 'mdi-plus'
              },
              {
                title: this.$t('interface.tokens.remove_token'),
                to: 'remove',
                iconName: 'mdi-minus'
              }
            ]
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters('wallet', ['tokensList', 'web3']),
    ...mapState('wallet', ['web3', 'loadingWalletInfo']),
    ...mapState('wallet', ['address']),
    ...mapGetters('custom', ['customTokens', 'hasCustom']),
    ...mapGetters('global', [
      'isEthNetwork',
      'network',
      'hasSwap',
      'getFiatValue',
      'typeERC'
    ]),
    ...mapGetters('external', ['totalTokenFiatValue']),
    loading() {
      return this.loadingWalletInfo;
    },
    /**
     * @returns formatted custom token values and token list values
     * displays custom tokens first and then token list
     * will be sorted by usd balance for both
     */
    tokensData() {
      if (!this.tokensList && !this.customTokens) return [];

      const customTokens = this.customTokens.map(item => {
        return this.formatValues(item);
      });

      const uniqueTokens = uniqWith(this.tokensList, isEqual);
      const tokenList = uniqueTokens.map(item => {
        return this.formatValues(item);
      });
      // tokenList.sort((a, b) => b.usdBalance - a.usdBalance);
      const tokens = tokenList.concat(customTokens);

      return tokens;
    },
    totalTokensValue() {
      return this.getFiatValue(this.totalTokenFiatValue);
    }
  },
  methods: {
    /**
     * @returns formatted values to display correctly on token table
     */
    formatValues(item) {
      const newObj = {};
      newObj.balance = [
        item.balancef
          ? item.balancef + ' ' + item.symbol
          : '0' + ' ' + item.symbol,
        item.usdBalancef ? this.getFiatValue(item.usdBalancef) : '0'
      ];
      newObj.ercType = item.ercType || 'ERC20';
      newObj.lockAmount = [
        item.lockAmount
          ? item.lockAmount + ' ' + item.symbol
          : '0' + ' ' + item.symbol
      ];
      newObj.usdBalance = item.usdBalance ? item.usdBalance : '0';
      newObj.token = item.symbol;
      newObj.cap = item.market_capf !== '0' ? item.market_capf : '';
      newObj.change =
        item.price_change_percentage_24hf &&
        item.price_change_percentage_24hf !== '0'
          ? item.price_change_percentage_24hf.replaceAll('%', '')
          : '';
      newObj.status = item.price_change_percentage_24h > 0 ? '+' : '-';
      const priceUF = currencyToNumber(item.pricef);
      newObj.price =
        item.pricef && priceUF.toString() !== '0'
          ? this.getFiatValue(item.pricef)
          : '';

      // Use eth.svg icon for ETH
      if (item.symbol == 'ETH') {
        newObj.tokenImg = require('@/assets/images/networks/eth.svg');
      } else {
        newObj.tokenImg = item.img ? item.img : this.network.type.icon;
      }

      // add lock for token.
      //author: phatho
      // newObj.lock = 'No';
      // if (
      //   compareAddress(item?.contract, MAIN_TOKENS[this.network.type.chainID])
      // )
      //   newObj.lock = 'Yes';
      // if(
      //   item?.contract.toUpperCase() == '0x121b0B338613ecF8AfAB5C3694E2aF34Ddf993c1'.toUpperCase()
      // || item?.contract.toUpperCase() == '0x548b47668D0B2D3587459a3A605ecf4e1FC2cDe2'.toUpperCase()
      // || item?.contract.toUpperCase() == '0x381ff9a05c63badac7dd8b031840e19032d0eae4'.toUpperCase()
      // )
      //   newObj.lock = 'Yes'

      if (this.hasSwap) {
        //==============hard-code type token===================
        newObj.callToAction = [
          {
            title: item.ercType || 'ERC20',
            method: () => {
              if (item.ercType === 'ERC721') {
                this.$router.push({
                  name: ROUTES_WALLET.TOKEN_TYPE.NAME
                });
              }
            },
            btnStyle: 'outline',
            colorTheme: 'greenPrimary'
          },
          //==============hard-code type token===================
          {
            title: this.$t('home.btn_swap'),
            method: () => {
              Toast(this.$t('home.toast_currently_inactive'), {}, WARNING);
              // const obj = {
              //   fromToken: item.contract,
              //   amount: item.balancef
              // };
              // this.$router
              //   .push({
              //     name: ROUTES_WALLET.SWAP.NAME,
              //     query: obj
              //   })
              //   .then(() => {
              //     this.$emit('trade');
              //   });
            },
            btnStyle: 'outline',
            colorTheme: 'greenPrimary'
          }
        ];
      } else {
        //==============hard-code type token===================
        newObj.callToAction = [
          {
            title: item.ercType || 'ERC20',
            method: () => {
              if (item.ercType === 'ERC721') {
                this.$router.push({
                  name: ROUTES_WALLET.TOKEN_TYPE.NAME,
                  params: {
                    token: item
                  }
                });
              }
            },
            btnStyle: 'outline',
            colorTheme: 'greenPrimary'
          }
        ];

        //==============hard-code type token===================
      }

      return newObj;
    },
    toggleAddCustomToken() {
      this.openAddCustomToken = !this.openAddCustomToken;
    },
    toggleDeleteCustomToken() {
      this.openDeleteCustomToken = !this.openDeleteCustomToken;
    },
    customTokenAction(param) {
      if (param === 'add') {
        this.toggleAddCustomToken();
      } else if (param === 'remove') {
        this.toggleDeleteCustomToken();
      }
    }
  }
};
</script>

<style lang="scss">
.module-tokens {
  .mew-table td.text-start:nth-last-of-type(2) div span:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.mytoken {
  background: none !important;
}

.v-application .ma-md-n3 {
  background-color: transparent !important;
}

.v-application .white {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff !important;
}

.module-container {
  background-color: transparent !important;
}

.table_tokens .mew-table.v-data-table {
  color: #ffffff !important;
}

.table_tokens .mew-table.v-data-table,
.mew-table table .v-data-table-header {
  background-color: transparent !important;
}

.table_tokens .mew-table table {
  border: 1px solid #7c7c7c !important;
  color: #ffffff !important;
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.custom_token {
  color: #ffffff !important;
}
.v-menu__content .v-sheet.v-list:not(.v-sheet--outlined) {
  background: linear-gradient(
    180deg,
    rgba(20, 20, 22, 1) 0%,
    rgba(13, 95, 123, 1) 100%
  ) !important;
  color: red;
}
.text_custom_token {
  color: #ffffff !important;
}
.v-application .basic--text,
.v-application .basic--text .v-icon {
  color: #05b47c !important;
  font-weight: 600;
}
.table_tokens
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}
.v-application .titlePrimary--text {
  color: #ffffff !important;
}

.table_tokens .v-data-table__wrapper {
  overflow-y: scroll;
  max-height: 170px;
  position: relative;
}
.table_tokens .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.table_tokens .v-data-table__wrapper::-webkit-scrollbar {
  width: 5px;
}

.table_tokens .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.au_token_custom .left-wrapper.pl-8 {
  background-color: transparent !important;
}

.table_tokens .v-data-table__wrapper thead th {
  position: sticky;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(20, 20, 22, 1) 0%,
    rgba(13, 95, 123, 1) 100%
  ) !important;
  z-index: 99;
  font-size: 15px !important;
  opacity: 1;
}

.my_token_value .v-data-table .v-data-table__wrapper thead tr th {
  background: linear-gradient(
    180deg,
    rgba(20, 20, 22, 1) 0%,
    rgba(13, 95, 123, 1) 100%
  ) !important;
  opacity: 1;
  font-size: 15px !important;
}

.my_token_value .v-data-table .v-data-table__wrapper tbody tr td:nth-child(3) {
  color: #05b47c !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.my_token_value .v-data-table .v-data-table__wrapper tbody tr td span {
  color: #fff !important;
}

.table_tokens .v-data-table__wrapper tbody tr td:nth-child(3) {
  color: #05b47c !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

@media (max-width: 768px) {
  .au_token_custom .left-wrapper.pl-8 {
    padding-left: 0px !important;
  }
  .container .right-wrapper.pr-10 {
    padding-right: 0px !important;
  }

  .table_tokens .v-data-table__wrapper {
    overflow-y: scroll;
    max-height: 265px;
  }
}
</style>
