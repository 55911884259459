/* eslint camelcase: 0 */
import common from './common/zh_CN';
import confirmation from './confirmation/zh_CN';
import dappsMaker from './dapps-maker/zh_CN';
import dappsSubmission from './dapps-submission/zh_CN';
import home from './home/zh_CN';
import footer from './footer/zh_CN';
import gettingStarted from './getting-started/zh_CN';
import privacyPolicy from './privacy-policy/zh_CN';
import termsOfService from './terms-of-conditions/zh_CN';
import createWallet from './create-wallet/zh_CN';
import accessWallet from './access-wallet/zh_CN';
import interfaceTranslations from './interface/zh_CN';
import team from './team/zh_CN';
import popover from './popover/zh_CN';
import withoutWallet from './without-wallet/zh_CN';
import errorsGlobal from './errors-global/zh_CN';
import trezorError from './errors-trezor/zh_CN';
import bcvaultError from './errors-bcvault/zh_CN';
import coolWalletError from './errors-coolwallet/zh_CN';
import ledgerError from './errors-ledger/zh_CN';
import keepkeyError from './errors-keepkey/zh_CN';
import bitboxError from './errors-bitbox/zh_CN';
import bitbox02Error from './errors-bitbox02/zh_CN';
import buyHardwareWallet from './other/buy-hardware-wallet/zh_CN';
import convertUnits from './other/convert-units/zh_CN';
import verifyMessage from './other/verify-message/zh_CN';
import welcomeMessage from './welcome-message/zh_CN';
import sendTx from './send-tx/zh_CN';
import swap from './swap/zh_CN';
import contract from './contract/zh_CN';
import ens from './dapps-ens/zh_CN';
import unstoppable from './dapps-unstoppable/zh_CN';
import subDomain from './dapps-sub-domain/zh_CN';
import scheduleTx from './dapps-schedule-tx/zh_CN';
import nftManager from './nft-manager/zh_CN';
import signMessage from './sign-message/zh_CN';
import helpCenter from './help-center/zh_CN';
import notifications from './notifications/zh_CN';
import mewcx from './mew-cx/zh_CN';
import ambrpay from './dapps-ambrpay/zh_CN';
import phishing from './other/phishing/zh_CN';
import aave from './dapps-aave/zh_CN';
import header from './header/zh_CN';
import mcdMaker from './dapps-mcd-maker/zh_CN';
import dappsStaked from './dapps-staked/zh_CN';

const zh_CN = {
  common: common,
  confirmation: confirmation,
  home: home,
  footer: footer,
  gettingStarted: gettingStarted,
  privacyPol: privacyPolicy,
  termsOfService: termsOfService,
  team: team,
  accessWallet: accessWallet,
  createWallet: createWallet,
  interface: interfaceTranslations,
  popover: popover,
  withoutWallet: withoutWallet,
  errorsGlobal: errorsGlobal,
  trezorError: trezorError,
  coolWalletError: coolWalletError,
  ledgerError: ledgerError,
  bcvaultError: bcvaultError,
  keepkeyError: keepkeyError,
  bitboxError: bitboxError,
  bitbox02Error: bitbox02Error,
  buyHardwareWallet: buyHardwareWallet,
  dappsMaker: dappsMaker,
  convertUnits: convertUnits,
  verifyMessage: verifyMessage,
  welcomeMessage: welcomeMessage,
  sendTx: sendTx,
  swap: swap,
  contract: contract,
  ens: ens,
  unstoppable: unstoppable,
  subDomain: subDomain,
  scheduleTx: scheduleTx,
  nftManager: nftManager,
  signMessage: signMessage,
  dappsSubmission: dappsSubmission,
  helpCenter: helpCenter,
  notifications: notifications,
  mewcx: mewcx,
  ambrpay: ambrpay,
  phishing: phishing,
  aave: aave,
  header: header,
  mcdMaker: mcdMaker,
  dappsStaked: dappsStaked
};
export default zh_CN;
