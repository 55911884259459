<template>
  <div class="mew-overview pt-5 d-flex justify-space-between align-items-center">
    <div class="img_left">
      <img
        src="../../../assets/images/create/person.png"
        alt="person"
        width="95%"
      />
    </div>

    <div class="create_access">
      <div class="img_top">
        <img src="../../../assets/images/create/people.png" alt="person"/>
      </div>
      <!--
      =====================================================================================
        Keystore File Button
      =====================================================================================
      -->
      <div class="mb-8">
        <button
          has-full-width
          color-theme="greyMedium"
          btn-style="outline"
          style="min-height: 160px; padding: 13px"
          @click="selectWalletType(walletTypes.KEYSTORE)"
          class="mnemonic_phrase"
        >
          <div
            class="text-left d-flex align-center justify-space-between px-3"
            style="width: 100%"
          >
          <img
              width="78"
              height="92px"
              class="d-sm-block ms-5"
              src="../../../assets/images/create/icon1.png"
            />

            <div class="key_mnemomic_des">
              <div class="mew-heading-2 key_mnemomic_title">{{$t('createWallet.keystore.title')}}</div>
              <div class="break-word key_mnemomic">
                {{$t('createWallet.keystore.desc_title')}}
              </div>
            </div>
            
          </div>
        </button>
      </div>

      <!--
      =====================================================================================
        Mnemonic Phrase Button
      =====================================================================================
      -->
      <div class="">
        <button
          has-full-width
          color-theme="greyMedium"
          btn-style="outline"
          style="min-height: 160px; padding: 13px"
          @click="selectWalletType(walletTypes.MNEMONIC)"
          class="mnemonic_phrase"
        >
          <div
            class="text-left d-flex align-center justify-space-between px-3"
            style="width: 100%"
          >
          <img
              width="78"
              height="92"
              class="d-sm-block ms-5"
              src="../../../assets/images/create/icon2.png"
            />

            <div class="key_mnemomic_des">
              <div class="mew-heading-2 key_mnemomic_title">
                {{$t('createWallet.mnemonic.title_1')}}
              </div>
              <div class="break-word key_mnemomic">
                {{$t('createWallet.mnemonic.desc_title_1')}}
              </div>
            </div>
            
          </div>
        </button>
      </div>

      <!--
      =====================================================================================
        Warning Block
      =====================================================================================
      -->
      <!-- <mew-warning-sheet
        class="mt-3"
        title="NOT RECOMMENDED"
        :link-obj="linkToLearnMore"
        description="This information is sensitive, and these options should only be used in offline settings by experienced crypto users."
      /> -->
    </div>
  </div>
</template>

<script>
import WALLET_TYPES from '@/modules/access-wallet/common/walletTypes';
import { mapGetters } from 'vuex';
export default {
  name: 'CreateWalletSoftwareOverview',
  data: () => ({
    walletTypes: WALLET_TYPES,
    linkToLearnMore: {
      url: '',
      title: 'Learn more'
    }
  }),
  computed: {
    ...mapGetters('article', ['getArticle'])
  },
  mounted() {
    this.linkToLearnMore.url = this.getArticle('not-rec-when-access-wallet');
  },
  methods: {
    /**
     * Emit wallet type creation.
     * @type - is part of WALLET_TYPES
     * function is void
     */
    selectWalletType(type) {
      this.$emit('typeSelect', type);
    }
  }
};
</script>

<style lang="scss" scoped>
.mew-overview {
  max-width: 100%;
}
.key_mnemomic_title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px !important;
  line-height: 38px;
  text-align: center;
  padding-bottom: 15px !important;
  color: #ffffff !important;
}
.key_mnemomic {
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 150%;
  text-transform: capitalize;
  color: #ffffff !important;
  padding-left: 20px;
}
.mnemonic_phrase {
  background-image: url(~@/assets/images/create/bg_icon_mobile.png) !important;
  background-size: 100% 100% !important;
  background-position: center !important;
  padding: 0 80px;
}
.key_mnemomic_des {
  margin-right: 10px;
  margin-bottom: 10px;
}

.img_left{
  margin-right: 20px !important;
}

.img_top{
  display: none;
}

@media (max-width: 768px) {
  .mnemonic_phrase {
    padding: 0 10px;
  }

  .key_mnemomic_title {
    font-size: 20px !important;
    line-height: 50px;
    padding-bottom: 0px !important;
  }
  .key_mnemomic {
    padding-bottom: 30px;
    font-size: 12px !important;
    padding-left: 16px;
  }
  .key_mnemomic_des {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .img_top{
    display: block;
    width: fit-content;
    margin: 0 auto;
  }
  .img_left{
    display: none;
    margin-left: 0 !important;
  }
}
</style>
