<template>
  <div class="component--wallet-card">
    <!-- <div class="mew-card drop-shadow">
      <img
        :src="'https://mewcard.mewapi.io/?address=' + address"
        alt="MEW Card"
        @load="animateMewCard()"
      />
    </div> -->
    <div class="info-container pl-8 pr-5 text-shadow">
      <div class="d-flex flex-row align-start">
        <div>
          <!--
          =====================================================================================
            Address
          =====================================================================================
          -->
          <v-menu offset-y>
            <template #activator="{ on }">
              <div
                class="d-flex align-center cursor--pointer personal-account-container"
                v-on="on"
              >
                <div
                  class="info-container--text font-weight-bold text-uppercase white--text my_personal"
                >
                  {{ $t('common.my_personal_account') }}
                </div>
                <v-icon class="white--text ml-2 icon_dropdown" small dense>
                  mdi-menu-down
                </v-icon>
              </div>
            </template>
            <v-list width="" class="mt-0 personal_account">
              <v-list-item class="cursor-pointer" @click="refresh">
                <v-icon color="white" class="mr-3 icon_my_personal"
                  >mdi-refresh</v-icon
                >
                <v-list-item-title class="model_my_personal">
                  {{ $t('common.refresh_balance') }}</v-list-item-title
                >
              </v-list-item>
              <v-list-item class="cursor-pointer" @click="openPaperWallet">
                <v-icon color="white" class="mr-3 icon_my_personal"
                  >mdi-printer</v-icon
                >
                <v-list-item-title class="model_my_personal">{{
                  $t('common.view_paper_wallet')
                }}</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item
                v-if="isHardware && canDisplayAddress"
                class="cursor-pointer"
                @click="viewAddressOnDevice"
              >
                <mew-icon
                  :icon-name="iconIdentifier"
                  :img-height="24"
                  class="mr-3"
                />
                <v-list-item-title
                  >View address on {{ walletName }}</v-list-item-title
                >
              </v-list-item>
              <v-divider class="mx-5 my-4"></v-divider>
              <v-list-item
                v-if="canSwitch"
                class="cursor-pointer"
                @click="openChangeAddress"
              >
                <v-icon color="white" class="mr-3 icon_my_personal"
                  >mdi-account-box-multiple</v-icon
                >
                <v-list-item-title class="model_my_personal">Switch Account</v-list-item-title>
              </v-list-item> -->

              <!-- <v-list-item
                class="settings_dashboard"
                v-for="(item, idx) in sectionTwo"
                :key="item + idx"
                :to="item.route"
                @click="item.fn()"
              >
                <v-list-item-icon class="icon_setting">
                  <img class="icon" :src="item.icon" :alt="item.title" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="white--text mew-body font-weight-regular"
                  >
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item class="cursor-pointer" @click="openLogout">
                <v-icon color="white" class="mr-3 icon_my_personal"
                  >mdi-logout</v-icon
                >
                <v-list-item-title class="model_my_personal">{{
                  $t('common.logout')
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="d-flex align-center" @click="copyAddress">
            <v-tooltip top content-class="tooltip-inner">
              <template #activator="{ on }">
                <div
                  class="justify-start d-flex align-center info-container--addr monospace"
                  v-on="on"
                >
                  {{ addrFirstSix }}
                  <v-icon class="info-container--addr pt-1"
                    >mdi-dots-horizontal</v-icon
                  >

                  {{ addrlastFour }}
                </div>
              </template>
              <span class="textDark--text">{{ getChecksumAddressString }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <!--
      =====================================================================================
        Total Wallet FIAT balance OR if Test network Chain Balance
      =====================================================================================
      -->
      <div
        v-if="!isOfflineApp"
        class="mew-subtitle text-shadow white--text mt-5 mb-4 total_bnb"
      >
        {{ totalWalletBalance }}
        <span style="padding-left: 10px; font-size: 30px">
          {{ mainToken.symbol }}
          <!-- {{ network.type.currencyName }} -->
        </span>
        <!-- <img :src="mainToken.img" style="margin-left: 10px" height="100"/> -->
      </div>
      <div
        class="d-flex justify-space-between align-center"
        :style="isOfflineApp ? 'margin-top:74px' : ''"
      >
        <div v-if="!isOfflineApp" class="justify-start">
          <!--
          =====================================================================================
            Total Wallet chain balance: prensent if not Test network
          =====================================================================================
          -->
          <!-- <div v-if="!isTestNetwork" class="info-container--text-chain-balance">
            {{ walletChainBalance }} {{ network.type.currencyName }}
          </div> -->
          <!--
          =====================================================================================
            Total Tokens: present if tokens found
          =====================================================================================
          -->
          <!-- <div v-if="nonChainTokensCount > 0" class="info-container--text">
            and {{ nonChainTokensCount }} Tokens
          </div> -->
        </div>
        <div class="d-flex justify-end">
          <!--
          =====================================================================================
            QR CODE
          =====================================================================================
          -->
          <v-btn
            class="info-container--action-btn mx-2 px-0"
            fab
            depressed
            color="white"
            @click="openQR = true"
            ><v-icon class="info-container--icon text_gray" size="18px"
              >mdi-qrcode</v-icon
            ></v-btn
          >
          <!--
          =====================================================================================
            Copy Button
          =====================================================================================
          -->
          <v-btn
            class="info-container--action-btn px-0"
            depressed
            fab
            color="white"
            @click="copyAddress"
            ><v-icon class="info-container--icon" small
              >mdi-content-copy</v-icon
            ></v-btn
          >
        </div>
      </div>
    </div>
    <!--
    =====================================================================================
      Wallet card modals
    =====================================================================================
    -->
    <balance-address-paper-wallet
      :open="showPaperWallet"
      :close="closePaperWallet"
      @close="closePaperWallet"
    />
    <app-modal
      :show="openQR"
      :close="closeQR"
      :has-buttons="false"
      width="408px"
    >
      <template #dialogBody>
        <app-addr-qr />
      </template>
    </app-modal>
    <module-access-wallet-hardware
      v-if="showHardware"
      :open="showChangeAddress"
      :close="closeChangeAddress"
      :switch-address="!!instance.path"
    />
    <module-access-wallet-software
      v-else
      :open="showChangeAddress"
      :close="closeChangeAddress"
      :switch-address="!!instance.path"
      :wallet-type="identifier"
    />

    <mew-popup
      max-width="400px"
      :class="aaaaaa"
      hide-close-btn
      :show="showLogout"
      :title="$t('interface.menu.logout')"
      :left-btn="{
        text: $t('interface.cancel'),
        method: closeLogout,
        color: 'basic'
      }"
      :right-btn="{
        text: $t('interface.logout'),
        color: 'error',
        method: onLogout,
        enabled: true
      }"
    />
    <mew-popup
      max-width="515px"
      :show="showVerify"
      :title="verifyAddressTitle"
      :has-buttons="false"
      :has-body-content="true"
      :left-btn="{ text: 'Cancel', method: closeVerify, color: 'basic' }"
    >
      <div>
        <div class="text-center">
          {{ verifyAddressBody }}
        </div>
        <div class="mt-3 verify-popup-border px-12 py-5 text-center">
          <div class="font-weight-bold greenPrimary--text mew-body">
            ACCOUNT ADDRESS
          </div>
          <div class="pt-3 greenPrimary--text mew-body">
            {{ getChecksumAddressString }}
          </div>
        </div>
      </div>
    </mew-popup>
    <module-settings :on-settings="onSettings" @closeSettings="closeSettings" />
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import AppModal from '@/core/components/AppModal';
import AppAddrQr from '@/core/components/AppAddrQr';
import BalanceAddressPaperWallet from './components/BalanceAddressPaperWallet';
import { mapGetters, mapActions, mapState } from 'vuex';
import clipboardCopy from 'clipboard-copy';
import { Toast, INFO, SUCCESS } from '@/modules/toast/handler/handlerToast';
import { toChecksumAddress } from '@/core/helpers/addressUtils';
import { formatFloatingPointValue } from '@/core/helpers/numberFormatHelper';
import { isEmpty } from 'lodash';
import ModuleAccessWalletHardware from '@/modules/access-wallet/ModuleAccessWalletHardware';
import ModuleAccessWalletSoftware from '@/modules/access-wallet/ModuleAccessWalletSoftware';
import wallets from './handlers/config';
import WALLET_TYPES from '../access-wallet/common/walletTypes';

import ModuleSettings from '@/modules/settings/ModuleSettings';
import { ROUTES_WALLET } from '@/core/configs/configRoutes';
import { STAKEWISE_EVENT } from '@/dapps/stakewise/helpers/index';
import { STAKEWISE_ROUTES } from '@/dapps/stakewise/configsRoutes';
import { EventBus } from '@/core/plugins/eventBus';
import settings from '@/assets/images/icons/icon-setting-enable.svg';
import logout from '@/assets/images/icons/icon-logout-enable.svg';

export default {
  components: {
    BalanceAddressPaperWallet,
    AppModal,
    AppAddrQr,
    ModuleAccessWalletHardware,
    ModuleAccessWalletSoftware,
    ModuleSettings
  },
  data() {
    return {
      showChangeAddress: false,
      showPaperWallet: false,
      openQR: false,
      showLogout: false,
      showVerify: false,
      wallets: wallets,
      isReloadedBalance: false,
      onSettings: false,
      showLogoutPopup: false
    };
  },
  computed: {
    ...mapState('wallet', [
      'address',
      'instance',
      'identifier',
      'isHardware',
      'isOfflineApp'
    ]),
    ...mapState('global', ['online']),
    ...mapGetters('external', ['totalTokenFiatValue']),
    ...mapGetters('global', ['network', 'isTestNetwork', 'getFiatValue']),
    ...mapGetters('wallet', ['tokensList', 'balanceInETH', 'getTokenInfo']),

    /**
     * verify address title
     * returns @String
     */
    verifyAddressTitle() {
      return `This wallet is accessed with ${this.instance.meta.name}`;
    },
    /**
     * verify address body
     * returns @String
     */
    verifyAddressBody() {
      return `To verify, check the address on your ${this.instance.meta.name} device & make sure it is the same address as the one shown below.`;
    },
    /**
     * Shows hardware access or software access
     * returns @Boolean
     */
    showHardware() {
      return (
        !isEmpty(this.instance) &&
        this.instance.path &&
        this.identifier !== WALLET_TYPES.MNEMONIC
      );
    },
    /**
     * returns checksummed address
     */
    getChecksumAddressString() {
      return this.address ? toChecksumAddress(this.address) : '';
    },
    /**
     * checks whether hardware wallet
     * can display address with the device
     *
     * returns @Boolean
     */
    canDisplayAddress() {
      return (
        !isEmpty(this.instance) &&
        this.instance.hasOwnProperty('displayAddress') &&
        this.instance.displayAddress
      );
    },
    /**
     * adds checks for icons that mew-components doesn't have
     * returns @String
     */
    iconIdentifier() {
      if (this.identifier === WALLET_TYPES.BITBOX2) {
        return 'bitbox';
      }
      return this.identifier;
    },
    /**
     * checks whether wallet can switch address
     * returns @Boolean
     */
    canSwitch() {
      return !isEmpty(this.instance) && this.wallets[this.identifier];
    },
    /**
     * returns hardware wallet name
     * returns @String
     */
    walletName() {
      return !isEmpty(this.instance) &&
        this.instance.meta.hasOwnProperty('name')
        ? this.instance.meta.name
        : '';
    },
    /**
     * returns token values
     * returns @String
     */
    totalTokenBalance() {
      return this.totalTokenFiatValue;
    },
    /**
     * returns total value including tokens
     * returns @String
     */
    totalWalletBalance() {
      // if (!this.isTestNetwork) {
      //   const total = this.totalTokenBalance;
      //   return this.getFiatValue(total);
      // }
      // console.log('totalTokenFiatValueC60',this.getFiatValue(this.totalTokenFiatValueC60));
      return this.walletChainBalance;
    },
    /**
     * returns formatted wallet balance
     * returns @String
     */
    walletChainBalance() {
      return `${formatFloatingPointValue(this.balanceInETH).value}`;
    },
    /**
     * @returns {string} first 6 letters in the address
     */
    addrFirstSix() {
      return this.address ? this.address.substring(0, 6) : '';
    },
    /**
     * @returns {string} lat 4 letters in the address
     */
    addrlastFour() {
      return this.address
        ? this.address.substring(this.address.length - 4, this.address.length)
        : '';
    },
    /**
     * @returns {number} count of non chain tokens
     */
    nonChainTokensCount() {
      return this.tokensList.length - 1;
    },
    mainToken() {
      return this.getTokenInfo;
    },
    sectionTwo() {
      if (this.online) {
        return [
          {
            title: this.$t('common.settings'),
            icon: settings,
            fn: this.openSettings,
            route: { name: ROUTES_WALLET.SETTINGS.NAME }
          }
          // {
          //   title: this.$t('common.logout'),
          //   icon: logout,
          //   fn: this.toggleLogout
          // }
        ];
      }
      // return [
      //   {
      //     title: this.$t('common.logout'),
      //     icon: logout,
      //     fn: this.toggleLogout
      //   }
      // ];
    }
  },
  watch: {
    isReloadedBalance() {
      this.setTokenAndEthBalance();
    }
  },
  methods: {
    ...mapActions('external', ['setTokenAndEthBalance']),
    ...mapActions('wallet', ['removeWallet']),
    /**
     * refreshes the token and eth balance
     */
    refresh() {
      this.setTokenAndEthBalance();
    },
    reloadBalance() {
      // setInterval(() => {
      //   this.isReloadedBalance = !this.isReloadedBalance;
      // }, 30000); // 0.5 mins
    },
    /**
     * calls hardware wallet show address function
     * and opens verify modal
     */
    viewAddressOnDevice() {
      this.showVerify = true;
      if (this.canDisplayAddress) {
        this.instance.displayAddress().then(() => {
          this.showVerify = false;
          Toast('Address verified!', {}, SUCCESS);
        });
      }
    },
    /**
     * Animates wallet card
     */
    animateMewCard() {
      const el = document.querySelector('.mew-card');
      if (el) {
        el.style.opacity = 0;
        anime({
          targets: el,
          opacity: 1,
          delay: 1300,
          duration: 500,
          easing: 'easeInOutQuad'
        });
      }
    },
    /**
     * set showChangeAddress to false
     * to close the modal
     */
    closeChangeAddress() {
      this.showChangeAddress = false;
    },
    /**
     * set showChangeAddress to true
     * to open the modal
     */
    openChangeAddress() {
      this.showChangeAddress = true;
    },
    /**
     * set showPaperWallet to false
     * to close the modal
     */
    closePaperWallet() {
      this.showPaperWallet = false;
    },
    /**
     * sets showPaperWallet to true
     * to open the modal
     */
    openPaperWallet() {
      this.showPaperWallet = true;
    },
    /**
     * Copies address
     */
    copyAddress() {
      clipboardCopy(this.getChecksumAddressString);
      Toast(
        `${this.$t('common.copied')} ${this.getChecksumAddressString} ${this.$t(
          'common.successfully'
        )}`,
        {},
        SUCCESS
      );
    },
    /**
     * set openQR to false
     * to close the modal
     */
    closeQR() {
      this.openQR = false;
    },
    /**
     * set showLogout to false
     * to close the modal
     */
    closeLogout() {
      this.showLogout = false;
    },
    /**
     * close verify address
     */
    closeVerify() {
      this.showVerify = false;
    },
    /**
     * set showLogout to true
     * to open the modal
     */
    openLogout() {
      this.showLogout = true;
    },
    /**
     * calls removeWallet
     * and closes modal
     */
    onLogout() {
      this.closeLogout();
      this.removeWallet();
    },
    openSettings() {
      this.onSettings = true;
    },
    closeSettings() {
      this.onSettings = false;
      this.$router.go(-1);
    }
  },
  mounted() {
    if (this.$route.name == ROUTES_WALLET.SETTINGS.NAME) {
      this.openSettings();
    }
    EventBus.$on('openSettings', () => {
      this.openSettings();
    });
    EventBus.$on(STAKEWISE_EVENT, () => {
      this.$router.push({ name: STAKEWISE_ROUTES.CORE.NAME });
    });
  },
  created() {
    this.reloadBalance();
  }
};
</script>

<style lang="scss" scoped>
.component--wallet-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mew-card {
  opacity: 0;
  border-radius: 16px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  img {
    height: 100%;
    width: 100%;
  }
}

.v-btn::before {
  background-color: transparent;
}
.info-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 16px;
  width: 100%;
  position: relative;
  min-height: 172px;
  top: 0;
  left: 0;
  z-index: 1;
  .info-container--addr {
    font-size: 13px;
    line-height: 13px;
    color: white !important;
    font-weight: 500 !important;
    cursor: pointer;
  }
  .info-container--addr:hover {
    color: white;
  }
  .info-container--text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #ffffff !important;
  }
  .info-container--text-chain-balance {
    font-size: 14px;
    line-height: 20px;
    color: #333 !important;
    font-weight: 500 !important;
  }

  .info-container--action-btn {
    opacity: 0.6;
    border-radius: 10px !important;
    height: 32px !important;
    width: 32px !important;
    font-size: 16px !important;
  }

  .info-container--action- {
    opacity: 0.6;
    border-radius: 4px !important;
    height: 14px !important;
    width: 14px !important;
    font-size: 8px !important;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.24),
      0px 1px 4px 0px rgba(0, 0, 0, 0.24);
  }

  .info-container--action-btn:hover,
  .info-container--action-:hover {
    opacity: 1;
  }

  .info-container--icon:hover {
    color: var(--v-greenPrimary-base) !important;
  }
}

.text-shadow {
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24), 0px 1px 4px rgba(0, 0, 0, 0.24);
}

.drop-shadow {
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.24)),
    drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.24));
}

.refresh-icon:hover {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 32px;
  color: var(--v-white-base) !important;
  height: 20px;
  width: 20px;
}

.refresh-icon.v-icon.v-icon::after {
  background-color: transparent;
}

.personal-account-container {
  border-radius: 10px;
  &:hover {
    background: rgba(255, 255, 255, 0.08);
    padding-left: 8px;
    margin-left: -8px;
  }
}

.verify-popup-border {
  border: 1px solid var(--v-greenMedium-base);
  border-radius: 4px;
}
.v-application .align-center {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #b0b0b0 !important;
  justify-content: center;
}
.v-application .white--text {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: #ffffff !important;
}
.info-container .info-container--text-chain-balance {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #b0b0b0 !important;
}
.component--wallet-card,
.v-application .pl-8 {
  background-color: transparent !important;
}
.personal_account {
  background: linear-gradient(
    180deg,
    rgba(20, 20, 22, 1) 0%,
    rgba(13, 95, 123, 1) 100%
  ) !important;
  border-radius: 18.0345px;
  width: 100%;
  opacity: 1;
}
.model_my_personal {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-shadow: 0px 0px 189.653px rgba(13, 110, 253, 0.7);
}
.icon_my_personal {
  width: 32px !important;
}
.total_bnb {
  font-size: 70px !important;
}
.theme--light.v-card {
  color: #ffffff !important;
}
.icon_dropdown {
  font-size: 40px !important;
}
.settings_dashboard {
  max-height: 48px;
}
.icon_setting {
  display: block;
  margin: auto 8px auto 0 !important;
}
.icon {
  min-width: 33px;
  min-height: 33px;
  display: block;
  margin: auto 0;
}
@media (max-width: 768px) {
  .v-application .white--text {
    font-size: 16px;
  }

  .component--wallet-card {
    margin-top: 20px !important;
  }
  .icon_setting {
    margin: auto 14px auto 0 !important;
  }

  .icon {
    min-width: 18px;
    min-height: 18px;
    max-width: 18px;
    max-height: 18px;
    margin-left: 4px;
  }
}
</style>
