<template>
  <!--
  =====================================================================================
    Overlay - access using software
  =====================================================================================
  :footer="{
      text: 'Need help?',
      linkTitle: 'Contact support',
      link: 'mailto:support@myetherwallet.com'
    }" 

  -->
  <mew-overlay
    content-size="large"
    :show-overlay="open"
    :title="title"
    :back="showBackBtn && !switchAddress ? accessBack : null"
    :close="close"
  >
    <!--
    =====================================================================================
      Overview: prompts user to select options
    =====================================================================================
    -->
    <div v-if="walletType === types.OVERVIEW" class="">
      <div class="d-flex justify-space-between align-items-center mt-5">
        <div class="img_left my-auto">
          <img src="../../assets/images/create/person.png" alt="person" width="90%">
        </div>

        <div class="create_access">
          <div class="img_top">
            <img src="../../assets/images/create/people.png" alt="person">
          </div>

          <div v-for="(btn, key) in buttons" :key="key" class="mb-5 btn_custom">
          <mew-button
            has-full-width
            color-theme="greyMedium"
            btn-style="outline"
            style="height: 140px"
            @click.native="btn.fn"
          >
            <div
              class="text-left d-flex align-center justify-space-between px-2"
              style="width: 100%"
            >
              <img width="80" class="ml-2 d-sm-block" :src="btn.icon" />
              
              <div class="mew-heading-2 px-3 text_heading">
                {{ btn.label }}
              </div>
            </div>
          </mew-button>
        </div>
        </div>
      </div>
    </div>
    <!--
    =====================================================================================
      Access With Keystore
    =====================================================================================
    -->
    <access-wallet-keystore
      v-if="walletType === types.KEYSTORE"
      :handler-access-wallet="accessHandler"
      class="w-100"
      @unlock="unlockWallet"
    />
    <!--
    =====================================================================================
      Access With Mnemonic
    =====================================================================================
    -->
    <access-wallet-mnemonic
      v-if="walletType === types.MNEMONIC"
      :handler-access-wallet="accessHandler"
      :switch-address="switchAddress"
      class="w-100"
      @unlock="unlockWallet"
    />
    <!--
    =====================================================================================
      Access With PrivateKey
    =====================================================================================
    -->
    <access-wallet-private-key
      v-else-if="walletType === types.PRIVATE_KEY"
      :handler-access-wallet="accessHandler"
      class="w-100"
      @unlock="unlockWallet"
    />
    <!--
    =====================================================================================
      Warning
    =====================================================================================
    -->
    <!-- <mew-warning-sheet
      title="Not Recommended"
      description="This information is sensitive, and these options should only be used in offline settings by experienced crypto users."
      :link-obj="warningSheetObj"
      class="mt-0 mb-0"
    /> -->
  </mew-overlay>
</template>

<script>
import AccessWalletKeystore from './software/components/AccessWalletKeystore';
import AccessWalletMnemonic from './software/components/AccessWalletMnemonic';
import AccessWalletPrivateKey from './software/components/AccessWalletPrivateKey';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Toast, ERROR } from '@/modules/toast/handler/handlerToast';
import { SOFTWARE_WALLET_TYPES } from './software/handlers/helpers';
import handlerAccessWalletSoftware from './software/handlers/handlerAccessWalletSoftware';
import { ROUTES_WALLET } from '../../core/configs/configRoutes';
import handlerAnalytics from '@/modules/analytics-opt-in/handlers/handlerAnalytics.mixin';

export default {
  name: 'ModuleAccessWalletSoftware',
  components: {
    AccessWalletKeystore,
    AccessWalletMnemonic,
    AccessWalletPrivateKey
  },
  mixins: [handlerAnalytics],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
      default: () => {}
    },
    walletType: {
      type: String,
      default: ''
    },
    switchAddress: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: '',
      types: SOFTWARE_WALLET_TYPES,
      warningSheetObj: {
        title: 'Learn More',
        url: ''
      },
      buttons: [
        /* Keystore Button */
        {
          label: this.$t('accessWallet.json-file'),
          icon: require('@/assets/images/icons/icon-keystore-file.png'),
          fn: () => {
            this.setType(SOFTWARE_WALLET_TYPES.KEYSTORE);
          }
        },
        /* Mnemonic */
        {
          label: this.$t('accessWallet.mnemonic.string'),
          icon: require('@/assets/images/icons/icon-mnemonic.png'),
          fn: () => {
            this.setType(SOFTWARE_WALLET_TYPES.MNEMONIC);
          }
        },
        /* Private Key */
        {
          label: this.$t('accessWallet.private-key.string'),
          icon: require('@/assets/images/icons/icon-private-key-grey.png'),
          fn: () => {
            if (process.env.VUE_APP_PRIV_KEY) {
              this.accessHandler.unlockPrivateKeyWallet(
                process.env.VUE_APP_PRIV_KEY
              );
              this.unlockWallet();
            } else {
              this.setType(SOFTWARE_WALLET_TYPES.PRIVATE_KEY);
            }
          }
        }
      ],
      accessHandler: {}
    };
  },

  computed: {
    /**
     * @returns if the back button on overlay should be displayed
     */
    showBackBtn() {
      return this.walletType !== SOFTWARE_WALLET_TYPES.OVERVIEW;
    },
    /**
     * @returns correct title of the overlay according to the wallet type selected
     */
    title() {
      // {{$t('common.wallet.access') | lokalise('common.wallet.access')}}
      if (this.switchAddress) return 'Switch Address';
      switch (this.walletType) {
        case SOFTWARE_WALLET_TYPES.KEYSTORE:
          return this.$t('common.wallet.access');
        case SOFTWARE_WALLET_TYPES.MNEMONIC:
          return this.$t('common.wallet.access');
        case SOFTWARE_WALLET_TYPES.PRIVATE_KEY:
          return this.$t('common.wallet.access');
        default:
          return this.$t('common.wallet.access');
      }
    },
    ...mapState('external', ['path']),
    ...mapState('wallet', ['identifier']),
    ...mapGetters('article', ['getArticle'])
  },
  watch: {
    open(newVal) {
      if (this.identifier && this.switchAddress && newVal) {
        this.setType(this.identifier);
      }
    }
  },
  /**
   * Lifecycle hooks to create and destroy access wallet handler
   */
  mounted() {
    this.accessHandler = new handlerAccessWalletSoftware();
    this.warningSheetObj.url = this.getArticle('using-mew-offline');
  },
  destroyed() {
    this.accessHandler = {};
  },
  methods: {
    /**
     * Sets Wallet in the store
     */
    ...mapActions('wallet', ['setWallet']),

    /**
     * Sets Wallet and Pushes new route query param
     * Used in overlay back button
     * account is defined in Mnemonic phrase access
     */
    unlockWallet(account = undefined) {
      try {
        const wallet = !account
          ? this.accessHandler.getWalletInstance()
          : account;
        this.setWallet([wallet])
          .then(() => {
            if (this.switchAddress) {
              this.close();
              return;
            }
            if (this.path !== '') {
              this.$router.push({ path: this.path });
            } else {
              this.$router.push({ name: ROUTES_WALLET.WALLETS.NAME });
            }
            this.trackAccessWallet(this.type);
          })
          .catch(e => {
            Toast(e, {}, ERROR);
          });
      } catch (e) {
        Toast(e, {}, ERROR);
      }
    },

    /**
     * Directs user back to software overview
     * Pushes new route query param
     * Used in overlay back button
     */
    accessBack() {
      if (this.walletType !== SOFTWARE_WALLET_TYPES.OVERVIEW) {
        try {
          this.$router.push({
            query: { type: SOFTWARE_WALLET_TYPES.OVERVIEW }
          });
        } catch (e) {
          Toast(e, {}, ERROR);
        } //
      }
    },

    /**
     * Sets a wallet type, pushes new route
     * This method is used in create overview block
     * @type - must be one of the SOFTWARE_WALLET_TYPES
     */
    setType(newType) {
      if (Object.values(SOFTWARE_WALLET_TYPES).includes(newType)) {
        try {
          this.type = newType;
          this.$router.push({
            query: { type: newType }
          });
        } catch (e) {
          Toast(e, {}, ERROR);
        }
      } else {
        throw new Error('Not a valid type!');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.text_heading {
  font-size: 30px !important;
}

.btn_custom:last-child{
  margin-bottom: 0 !important;
}

.img_left{
  margin-right: 20px !important;
}

.img_top{
  display: none;
}

@media (max-width: 768px) {
  .text_heading {
    font-size: 20px !important;
    padding: 0 10px !important;
  }
  .img_top{
    display: block;
    width: fit-content;
    margin: 0 auto;
  }
  .img_left{
    display: none;
    margin-left: 0 !important;
  }
}
</style>
