<template>
<div>
  <div class="mew-component--side-info-network mt-7">
    <mew-overlay
      :show-overlay="isOpenNetworkOverlay"
      :title="$t('home.select_network')"
      content-size="large"
      :close="closeNetworkOverlay"
    >
      <network-switch
        :filter-types="filterNetworks"
        :is-swap-page="isSwapPage"
      />
    </mew-overlay>
    <div
      :sideinfo="!mobile"
      class="px-5 px-lg-7 py-5 d-flex justify-space-between aaaaaa"
    >
      <div class="bg_boder">
        <div class="d-flex align-center">
          <span class="mew-heading-2 mr-2 text_white">{{ $t('common.network') }}</span>
          <v-btn
            v-if="show"
            depressed
            color="greyLight"
            class="title-button"
            @click.native="openNetworkOverlay"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>

        <div class="mt-4" >
          <div class="mb-1 custom__network" style="color:white">{{ type }} - {{ fullName }}</div>
          <!-- <div style="color:white">Last Block: {{ lastBlock }}</div> -->
        </div>
      </div>
      <v-img :src="icon" :max-height="65" :max-width="65" contain />
    </div>
  </div>
  <div v-if="hasHistory" class="tx_history">
    <module-transfer-history />
  </div>
</div>
</template>

<script>
import NetworkSwitch from './components/NetworkSwitch';
import ModuleTransferHistory from '@/modules/transfer-history/ModuleTransferHistory';
import { mapGetters, mapState } from 'vuex';
import { formatIntegerToString } from '@/core/helpers/numberFormatHelper';
import WALLET_TYPES from '../access-wallet/common/walletTypes';
import { ROUTES_HOME, ROUTES_WALLET } from '@/core/configs/configRoutes';
export default {
  name: 'ModuleNetwork',
  components: { 
    NetworkSwitch, 
    ModuleTransferHistory
    },
  beforeRouteLeave(to, from, next) {
    if (to.name == ROUTES_HOME.ACCESS_WALLET.NAME) {
      next({ name: ROUTES_WALLET.DASHBOARD.NAME });
    } else {
      next();
    }
  },
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpenNetworkOverlay: false
    };
  },
  computed: {
    ...mapState('wallet', ['blockNumber', 'identifier', 'isHardware']),
    ...mapGetters('global', ['network']),
    ...mapGetters('notifications', ['txNotifications']),

    type() {
      return this.network.type.name;
    },
    fullName() {
      return this.network.type.name_long;
    },
    lastBlock() {
      return formatIntegerToString(this.blockNumber);
    },
    icon() {
      return this.network.type.icon;
    },
    show() {
      return this.identifier !== WALLET_TYPES.WEB3_WALLET;
    },
    /**
     * IMPORTANT TO DO:
     * @returns {boolean}
     */
    filterNetworks() {
      if (this.isHardware) {
        return [];
      }
      return [];
    },
    /**
     * Property returns whether or not you are on the swap page
     * @returns {boolean}
     */
    isSwapPage() {
      return this.$route.name === 'Swap';
    },
    hasHistory() {
      return this.txNotifications && this.txNotifications.length > 0;
    }
  },
  mounted() {
    this.$route.name == ROUTES_WALLET.NETWORK.NAME
      ? this.openNetworkOverlay()
      : '';
  },
  methods: {
    openNetworkOverlay() {
      this.$router.push({ name: ROUTES_WALLET.NETWORK.NAME });
      this.isOpenNetworkOverlay = true;
    },
    closeNetworkOverlay() {
      this.$router.go(-1);
      this.isOpenNetworkOverlay = false;
    }
  }
};
</script>

<style lang="scss">
.mew-component--side-info-network {
  border-radius: 15px;
  border: 1px solid #676e70 !important;
  /* padding: 5px 0; */


  .title-button {
    height: 28px !important;
    min-width: 28px !important;
    padding: 0 !important;
    border-radius: 5px;
  }
}
.mew-component--white-sheet.aaaaaa.v-sheet {
  background-color: transparent !important;
}
.custom__network {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tx_history {
  margin-top: 10px;
}
</style>
