<template>
  <div class="mew-component--create--mnemonic-phrase" style="width: 100%">
    <mew-stepper
      class="mx-md-0"
      :items="steppers"
      :on-step="step"
      style="margin: 0 auto"
    >
      <!--
      =====================================================================================
        Step 1: Write Down Words
      =====================================================================================
      -->
      <template v-if="step === 1" #stepperContent1>
        <div class="d-flex align-center justify-space-between">
          <div class="step1_write">
            <div class="font-weight-bold text_primary step_title">{{ $t('home.step_one') }}</div>
            <div class="font-weight-bold mb-5 create_step__title">
              {{ $t('createWallet.mnemonic.title_write_down') }}
            </div>

            <div class="change_top mt-5 align-center justify-end">
              <div
                class="greenPrimary--text cursor--pointer d-flex align-center mr-2 pa-2 mb-5 justify-end"
                @click="setPhrase"
              >
                <v-icon small color="greenPrimary" class="mr-1"
                  >mdi-sync</v-icon
                >
                <div class="font-weight-medium">{{ $t('createWallet.mnemonic.change') }}</div>
              </div>
              <v-select
                v-model="phraseSize"
                style="max-width: 150px; float: right"
                hide-details
                dense
                item-text="name"
                item-value="value"
                :items="mnemonicOptions"
                label=""
                outlined
                class="select_item mb-5 ms-5"
              ></v-select>
            </div>
            <!--
          =====================================================================================
            Phrase Table
          =====================================================================================
          -->
            <phrase-block class="mb-4 select_table_word">
              <mnemonic-phrase-table :data="phrase" />
            </phrase-block>
            <v-btn    class="ma-2"
            @click="copyPhrase()"
             style="border: 1px solid #05c0a5 !important; min-width: 64px !important" color="primary" outlined>{{ $t('createWallet.mnemonic.coppy') }}</v-btn>
          </div>
          <!--
          =====================================================================================
            Update Button & Word Number Selector
          =====================================================================================
          -->
          <div class="change_left">
            <div class="justify-end d-flex">
              <img src="@/assets/images/create/people.png" alt="img" />
            </div>

            <div
              class="greenPrimary--text cursor--pointer d-flex align-center mr-2 pa-2 justify-end"
              @click="setPhrase"
            >
              <v-icon small color="greenPrimary" class="mr-1">mdi-sync</v-icon>
              <div class="font-weight-medium">{{ $t('createWallet.mnemonic.change') }}</div>
            </div>
            <v-select
              v-model="phraseSize"
              style="max-width: 150px; float: right"
              hide-details
              dense
              item-text="name"
              item-value="value"
              :items="mnemonicOptions"
              label=""
              outlined
              class="select_item mb-5"
            ></v-select>
          </div>
        </div>
        <!--
          =====================================================================================
            Extra Word
          =====================================================================================
          -->
        <div class="d-flex">
          <!-- <mew-expand-panel
            :has-dividers="true"
            :is-toggle="true"
            :interactive-content="true"
            :panel-items="[
              {
                name: 'Extra Words (optional)'
              }
            ]"
            
          >-->
          <!-- <template  #panelBody1 >  -->
          <!-- <div
            class="d-flex align-center justify-space-between input_extra"
            style="width: 100%"
          >
            <div class="py-2">Extra Words (optional)</div>
            <div class="input_words py-2">
              <mew-input style="height: 62px !important" v-model="extraWord" />
            </div>
          </div> -->
          <!-- </template>
          </mew-expand-panel> -->
        </div>
        <!--
          =====================================================================================
           Button
          =====================================================================================
          -->
        <div class="d-flex justify-center pb-5 mt-5">
          <mew-button
            :title="next"
            btn-size="xlarge"
            class="btn_create btn_select"
            :has-full-width="false"
            @click.native="updateStep(2)"
          />
        </div>
        <!-- <mew-warning-sheet
          class="mt-5 mb-0"
          title="NOT RECOMMENDED"
          description="This information is sensitive, and these options should only be used in offline settings by experienced crypto users. Your phrase is the key to your wallet. Please make sure to write it down and save it in a secure location. We CAN NOT retrieve or reset your phrase if you lose it."
        /> -->
      </template>
      <!--
      =====================================================================================
        Step 2: Verification
      =====================================================================================
      -->
      <template v-if="step === 2" #stepperContent2>
        <div>
          <div class="font-weight-bold text_primary step_title">{{ $t('home.step_two') }}</div>
          <div class="font-weight-bold create_step__title">{{ $t('createWallet.mnemonic.verify.title') }}</div>
          <div class="text_des">
            {{ stepTwoText }}
          </div>
          <!--
          =====================================================================================
           Words Radio Group
          =====================================================================================
          -->
          <div class="mx-auto">
            <v-radio-group
              v-for="(item, idx) in generatedVerification"
              :key="`${idx}verification`"
              v-model="validateMnemonicValues[getOnlyKey(item)]"
              hide-details
              mandatory
              row
              class="radio-group"
            >
              <template #label>
                <div class="mew-heading-3 mb-3 mb-sm-0 num_text">
                  {{ getOnlyKey(item) + 1 }}.
                </div>
              </template>
              <v-row>
                <v-col
                  v-for="(entries, id) in getEntries(item)"
                  :key="entries + id"
                  cols="4"
                  sm="4"
                >
                  <v-radio
                    :label="entries"
                    :value="`${entries}_${id}`"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
            <mew-input
              v-if="extraWord && extraWord !== ''"
              v-model="extraWordVerification"
              :label="labelStep2"
              :placeholder="placeholderStep2"
              class="mt-10 mb-3"
            />
          </div>
          <!--
          =====================================================================================
           Back Button & Verify Button
          =====================================================================================
          -->
          <div class="d-flex flex-column flex-md-row justify-center mt-6">
            <mew-button
              :title="btnBack"
              btn-size="xlarge"
              btn-style="outline"
              class="mx-md-1 my-1 btn_veri btn_back"
              @click.native="updateStep(1)"
            />
            <mew-button
              :title="btnVerify"
              btn-size="xlarge"
              :disabled="!canVerify"
              class="mx-md-1 my-1 btn_veri btn_select"
              @click.native="verify"
            />
          </div>
        </div>
        <!-- <mew-warning-sheet
          class="mt-5 mb-0"
          title="NOT RECOMMENDED"
          description="This information is sensitive, and these options should only be used in offline settings by experienced crypto users. Your phrase is the key to your wallet. Please make sure to write it down and save it in a secure location. We CAN NOT retrieve or reset your phrase if you lose it."
        /> -->
      </template>
      <!--
      =====================================================================================
        Step 3: Done
      =====================================================================================
      -->
      <template v-if="step === 3" #stepperContent3>
        <div class="d-flex align-center justify-space-between">
          <div class="step3_create">
            <div class="font-weight-bold text_primary step_title">{{ $t('home.step_three') }}</div>
            <div class="font-weight-bold mb-3 create_step__title">
              {{ $t('createWallet.mnemonic.well_done') }}
            </div>
            <p class="text_des mb-5">
              {{ $t('createWallet.mnemonic.well_done_desc') }}
            </p>

            <div class="d-flex flex-column justify-center">
              <mew-button
                :title="btnStep3"
                btn-size="xlarge"
                :has-full-width="false"
                class="mb-5 btn_select btn-width"
                @click.native="goToAccess"
              />
              <mew-button
                :title="btnAnother"
                :has-full-width="false"
                btn-size="xlarge"
                class="btn_create btn_no_border btn-width"
                @click.native="updateStep(1)"
              />
            </div>
          </div>
          <v-img
            class="img_plane ml-8"
            max-width="350px"
            max-height="565px"
            src="@/assets/images/create/person.png"
          />
        </div>
      </template>
    </mew-stepper>
  </div>
</template>

<script>
import mnemonicPhraseTable from '@/components/MnemonicPhraseTable';
import phraseBlock from '@/components/PhraseBlock';
import { Toast, ERROR ,INFO, SUCCESS} from '@/modules/toast/handler/handlerToast';
import { ROUTES_HOME } from '@/core/configs/configRoutes';
import handlerAnalytics from '@/modules/analytics-opt-in/handlers/handlerAnalytics.mixin';
import WALLET_TYPES from '@/modules/access-wallet/common/walletTypes';
import clipboardCopy from 'clipboard-copy';
// import { Toast, INFO } from '@/modules/toast/handler/handlerToast';
export default {
  name: 'CreateWalletMnemonicPhrase',
  components: {
    mnemonicPhraseTable,
    phraseBlock
  },
  mixins: [handlerAnalytics],
  props: {
    handlerCreateWallet: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
    next: this.$t('home.btn_next'),
    labelStep2: this.$t('createWallet.mnemonic.verify.confirm_extra'),
    placeholderStep2: this.$t('createWallet.mnemonic.verify.please_confirm_extra'),
    btnBack: this.$t('createWallet.keystore.back'),
    btnVerify: this.$t('createWallet.mnemonic.verify.button'),
    btnStep3: this.$t('createWallet.keystore.btn_access'),
    btnAnother: this.$t('createWallet.keystore.btn_create_another'),
    step: 1,
    validateMnemonicValues: {},
    extraWord: '',
    extraWordVerification: '',
    steppers: [
      {
        step: 1
      },
      {
        step: 2
      },
      {
        step: 3
      }
    ],
    mnemonicOptions: [
      {
        name: this.$t('home.12_words'),
        value: 12
      },
      {
        name: this.$t('home.24_words'),
        value: 24
      }
    ],
    phraseSize: 12,
    phrase: [],
    generatedVerification: []
    };
  },
  computed: {
    canVerify() {
      return this.isValidMnemonic && this.extraWordMatch;
    },
    isValidMnemonic() {
      return this.phrase.length === this.phraseSize;
    },
    extraWordMatch() {
      return this.extraWord === this.extraWordVerification;
    },
    stepTwoText() {
      return this.extraWord === ''
        ? this.$t('createWallet.mnemonic.verify.title_desc1')
        : this.$t('createWallet.mnemonic.verify.title_desc2');
    }
  },
  watch: {
    phraseSize: {
      deep: true,
      handler: function (newVal) {
        this.phraseSize = newVal;
        this.setPhrase();
      }
    },
    phrase: {
      deep: true,
      handler: function () {}
    }
  },
  mounted() {
    this.setPhrase();
  },
  methods: {
    copyPhrase(){
      // const phrase_cp = this.phrase.replaceAll(",", " ");
      const phrase_cp = this.phrase.join(" ");
      console.log(this.phrase.join(" "));
        clipboardCopy(phrase_cp);
        // console.log(phrase_cp);
       Toast(this.$t('createWallet.mnemonic.toast_copy'), {}, SUCCESS);
    },
    generateVerification() {
      this.generatedVerification = this.handlerCreateWallet.getVerification();
      this.generatedVerification.sort(function (a, b) {
        return a.itemNumber - b.itemNumber;
      });
    },
    getOnlyKey(obj) {
      return Number(Object.keys(obj)[0]);
    },
    getEntries(obj) {
      return Object.values(obj[this.getOnlyKey(obj)]);
    },
    setPhrase() {
      this.handlerCreateWallet
        .generateMnemonic(this.phraseSize)
        .then(res => {
          this.phrase = res;
          this.generateVerification();
        })
        .catch(e => {
          this.generateVerification();
          Toast(e, {}, ERROR);
        });
    },
    verify() {
      this.handlerCreateWallet
        .validateMnemonic(this.validateMnemonicValues)
        .then(() => {
          this.trackCreateWallet(WALLET_TYPES.MNEMONIC);
          this.updateStep(3);
        })
        .catch(e => {
          Toast(e, {}, ERROR);
        });
    },
    /**
     * Reroutes to access wallet
     * Used in Step 3
     */
    goToAccess() {
      // this.$router.push({ name: ROUTES_HOME.ACCESS_WALLET.NAME });
      this.$router.push({ path: ROUTES_HOME.ACCESS_WALLET_SOFTWARE.PATH }); //custom Tan
    },

    /**
     * Updates Step
     * Resets phrase if step is reset to 1 from step 3 ( user is creating a new wallet)
     */
    updateStep(newStep) {
      if (this.step === 3 && newStep === 1) {
        this.setPhrase();
      }
      this.step = newStep;
    }
  }
};
</script>

<style lang="scss" scoped>
.mew-component--create--mnemonic-phrase .v-input--radio-group__input {
  flex-wrap: nowrap !important;
}

.mew-component--create--mnemonic-phrase .mew-stepper.v-stepper {
  background: transparent !important;
  width: 80% !important;
}

.radio-group {
  width: 100% !important;
  background-image: url(~@/assets/images/create/bg_3.png) !important;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.v-input__slot {
  height: 40px !important;
}
.input_extra {
  border: none !important;
  background: rgba(0, 255, 255, 0.2);
  border-radius: 10px;
  min-height: 65px;
  margin-bottom: 10px;
  color: #ffffff !important;
  font-size: 20px !important;
  padding: 0 50px;
}
.text_des {
  font-style: normal !important;
  color: #b1b1b1 !important;
  margin-bottom: 20px !important;
  font-size: 20px !important;
}

.step1_write {
  width: 75%;
}

.change_left {
  display: block !important;
}

.change_top {
  display: none !important;
}

.input_words {
  width: 60%;
}

.num_text {
  min-width: 30px;
  line-height: 25px;
  margin-left: 60px;
}

.btn_create {
  width: 50% !important;
}

.btn_veri {
  width: 50% !important;
}

.btn-width{
  width: 80% !important;
}

@media (max-width: 768px) {
  /* .mew-component--create--mnemonic-phrase {
    margin-top: 5rem !important;
  } */

  .btn-width{
  width: 100% !important;
}
  .step1_write {
    width: 100%;
  }

  .btn_create {
  width: 100% !important;
}

  .select_table_word {
    height: 365px;
    overflow-y: scroll;
  }

  .btn_veri {
    width: 100% !important;
  }

  .change_left {
    display: none !important;
  }

  .change_top {
    display: flex !important;
  }
  .input_words {
    width: 100%;
  }
  .input_extra {
    padding: 0 20px;
    flex-direction: column;
    font-size: 13px !important;
  }

  .num_text {
    min-width: 0px;
    line-height: 25px;
    margin-left: 10px;
  }

  .img_plane{
    display: none;
  }
}
</style>
