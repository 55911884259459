<template>
  <div>
    <div class="d-flex justify-space-between btn_back_close">
      <button
        class="btn_x text_white"
        onclick="
          window.history.go(-1);
          return false;
        "
      >
        &#8592;
      </button>
      <router-link
        :to="{ name: ROUTES_HOME.HOME.NAME, query: {} }"
        class="btn_x text_white"
      >
        x
      </router-link>
      <!-- <button
        class="btn_x text_white"
        @click="
          $router.push({
            path: '/'
          })
        "
      >
        x
      </button> -->
    </div>

    <div class="whitepaper_video py-8 px-15">
      <p class="title_text">
        {{
          $t('home.get-started.button-text-guide')
            | lokalise('home.get-started.button-text-guide')
        }}
      </p>

      <div class="w-60 mx-auto img_top">
        <img src="../../assets/images/create/people.png" width="85%" />
      </div>

      <div class="d-flex mt-5 align-center">
        <div class="img_left">
          <img src="../../assets/images/create/people.png" width="85%" />
        </div>

        <div class="content_whitepaper_video">
          <div
            class="d-flex align-center justify-space-between bg_whitepaper_video px-8 py-5"
          >
            <p class="text_white title_guide w-40">Whitepaper</p>

            <div class="d-flex flex-column w-60 ms-3 btn_multi">
              <a
                :href="$t('home.guide_whitepaper')"
                target="_blank"
                class="multi_whitepaper"
                >{{ $t('home.version') }}</a
              >
            </div>
          </div>

          <div
            class="d-flex align-center justify-space-between mt-10 bg_whitepaper_video px-8 py-5"
          >
            <p class="text_white title_guide w-40">Video</p>

            <div class="w-60 ms-3 btn_multi">
              <mew-button
                :title="$t('home.version_video')"
                btn-size="small"
                class="w-100 btn_back fs-20"
                :has-full-width="$vuetify.breakpoint.xs"
                @click.native="openGuideVideo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ROUTES_HOME } from '@/core/configs/configRoutes';
import { Toast, ERROR } from '@/modules/toast/handler/handlerToast';

export default {
  name: 'Guide',
  data() {
    return { ROUTES_HOME: ROUTES_HOME };
  },

  methods: {
    openGuideVideo() {
      try {
        this.$router.push({
          name: ROUTES_HOME.AU_WALLET_GUIDE_VIDEO.NAME
        });
      } catch (e) {
        Toast(e, {}, ERROR);
      }
    },
    close() {
      this.isDropdownOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.whitepaper_video {
  background-image: url(../../assets/images/bg/bg_dashboard.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title_text {
  font-size: 40px !important;
  text-shadow: 0px 0px 50px rgb(0 189 255 / 30%),
    0px 0px 80px rgb(13 110 253 / 70%);
  font-weight: 700;
  font-style: italic;
  text-align: center;
}

.title_guide {
  font-size: 30px !important;
  font-weight: bold;
}

.multi_whitepaper {
  background-image: url(../../assets/images/bg/bg_create_mobile.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 60px;
  text-align: center;
  color: #fff !important;
  line-height: 60px !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  letter-spacing: 2px !important;
}

.bg_whitepaper_video {
  background-image: url(../../assets/images/create/bg_icon_mobile.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 160px;
}

.img_top {
  display: none;
}

.img_left {
  display: block;
  width: 38%;
}

.content_whitepaper_video {
  width: 62% !important;
}

.btn_back_close {
  padding: 0 40px !important;
}

@media (max-width: 1536px) {
  .whitepaper_video {
    width: 60%;
  }
}

@media (max-width: 1366px) {
  .title_guide {
    font-size: 25px !important;
  }
}

@media (max-width: 768px) {
  .whitepaper_video {
    width: 100%;
    background: none !important;
    margin-top: 2rem;
  }
  .whitepaper_video.py-8 {
    padding: 0 50px !important;
  }

  .title_text {
    font-size: 35px !important;
  }

  .img_top {
    display: block;
  }

  .img_left {
    display: none;
  }

  .content_whitepaper_video {
    width: 100% !important;
  }

  .bg_whitepaper_video {
    flex-direction: column;
    background: none !important;
  }

  .d-flex .bg_whitepaper_video {
    padding: 0 !important;
  }

  .title_guide {
    width: 100% !important;
  }

  .btn_multi {
    width: 100% !important;
  }

  .btn_back_close {
    padding: 0 20px !important;
    margin-top: -1.5rem;
  }

  .btn_back_close .btn_x {
    font-size: 25px !important;
  }
}

@media (max-width: 375px) {
  .whitepaper_video.py-8 {
    overflow-y: scroll;
    height: 80vh;
  }
}
</style>
