import { MATIC } from '../types';
export default {
  type: MATIC,
  service: 'wallet.milkywaygalaxy.io',
  url: 'wss://polygon-mainnet.g.alchemy.com/v2/kbEA3lzvlHZ6G5fXe9s0pnIGNFN2pCql',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
