<template>
  <div>
    <the-default-header />
    <div>
      <router-view />
    </div>
    <!-- <the-default-footer /> -->
    <!-- <the-default-wallet-banner /> -->
  </div>
</template>

<script>
import TheDefaultHeader from './components-default/TheDefaultHeader';
// import TheDefaultFooter from './components-default/TheDefaultFooter';
// import TheDefaultWalletBanner from './components-default/TheDefaultWalletBanner';
export default {
  name: 'TheDefaultView',
  components: {
    TheDefaultHeader
    // TheDefaultFooter,
    // TheDefaultWalletBanner
  }
};
</script>
