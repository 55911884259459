<template>
  <div class="token_type__total">
    <div class="d-flex justify-space-between btn_back_close">
      <router-link
        :to="{ name: ROUTES_WALLET.DASHBOARD.NAME }"
        class="btn_x text_white"
      >
        x
      </router-link>
    </div>
    <div class="token">
      <div class="token_title">
        {{ tokenSelect }}
      </div>

      <div class="token_type">
        <div v-if="loading">
          <div class="token_loading">
            <v-skeleton-loader
              class="token_loading__img"
              type="image"
              width="32px"
              height="32px"
            />
            <v-skeleton-loader type="heading" width="100%" height="32px" />
          </div>
          <div class="token_loading">
            <v-skeleton-loader
              class="token_loading__img"
              type="image"
              width="32px"
              height="32px"
            />
            <v-skeleton-loader type="heading" width="100%" height="32px" />
          </div>
        </div>
        <div
          v-else
          v-for="(item, key) in listTokenIds"
          :key="key"
          class="token_item"
        >
          <div class="token_type_img">
            <img :src="item.image" :alt="item.name" />
          </div>
          <span class="token_type_title">
            <span>#{{ item.tokenId }}</span>
            <span style="font-weight: 400"> - {{ item.name }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ROUTES_WALLET } from '@/core/configs/configRoutes';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'TokenType',
  data() {
    return {
      ROUTES_WALLET: ROUTES_WALLET,
      title: 'C60',
      tokenType: [
        {
          title: 'C60',
          img: require('@/assets/images/tokens/c60.png'),
          label: 'img C60'
        },
        {
          title: 'LRPS',
          img: require('@/assets/images/tokens/lrps.png'),
          label: 'img LRPS'
        }
      ],
      listTokenIds: [],
      tokenSelect: '',
      tokenImage: '',
      loading: true
    };
  },
  computed: {
    ...mapState('wallet', ['address', 'web3'])
  },
  mounted() {
    this.loading = true;
    const token = this?.$router?.history?.current?.params?.token || null;

    if (token) {
      this.tokenSelect = token.name;
      this.getTokensOfOwner(token);
    }
  },
  methods: {
    async getTokensOfOwner(token) {
      const contract = new this.web3.eth.Contract(
        [
          {
            constant: true,
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'tokenOfOwnerByIndex',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
          },
          {
            constant: true,
            inputs: [
              { internalType: 'uint256', name: 'tokenId', type: 'uint256' }
            ],
            name: 'tokenURI',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
          },
          {}
        ],
        token.contract
      );

      const result = await Promise.all(
        Array.from({ length: Number(token.balance) }).map(async (_, idx) => {
          const id = await contract.methods
            .tokenOfOwnerByIndex(this.address, idx)
            .call();

          const uri = await contract.methods.tokenURI(id).call();

          let detail = {
            tokenId: id
          };

          try {
            const data = (await axios.get(uri)).data;

            if (data) detail = data;
          } catch {
            detail = {
              tokenId: id,
              name: id,
              image: 'img/logo.2c4ead1e.png'
            };
          }

          return detail;
        })
      );

      this.listTokenIds = result;
      this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.token_type__total {
  background: linear-gradient(
    180deg,
    rgba(20, 20, 22, 1) 0%,
    rgba(13, 95, 123, 1) 100%
  ) !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #707070 !important;
  border-radius: 10px;
  z-index: 99;
}

.btn_back_close {
  position: absolute;
  top: 0%;
  right: -4%;
  padding: 0 40px !important;
  z-index: 99;
}

.token_title {
  color: #ffffff;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
  padding-top: 30px;
}

.token_type {
  border: 1px solid #768287;
  border-radius: 10px;
  margin: 30px 60px 60px;
  max-height: 400px;
  overflow-y: auto;
}

.token_item {
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.token_item:not(:last-child) {
  border-bottom: 1px solid #768287;
}

.token_type_title {
  color: #ffffff;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  padding: 15px 10px;
}

.token_loading {
  display: flex;
  flex-direction: row;
  padding: 0px 16px;
  height: 64px;
  align-items: center;
}

.token_loading__img {
  margin-right: 16px;
}

.token_type_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  object-fit: cover;
  margin-left: 16px;
}

.token_type_img img {
  width: 100%;
}

.v-skeleton-loader__heading {
  width: 100% !important;
}

@media (max-width: 768px) {
  .btn_back_close {
    padding: 0 20px !important;
    margin-top: -1.5rem;
    top: 8%;
    right: 0%;
  }

  .btn_back_close .btn_x {
    font-size: 25px !important;
  }

  .token_type__total {
    width: 90%;
    top: 30%;
  }

  .token_type {
    margin: 30px 60px 120px;
  }

  .token_title {
    padding-top: 80px;
  }
}
</style>
