import { BSC } from '../types';
export default {
  type: BSC,
  service: 'wallet.milkywaygalaxy.io',
  url: 'wss://evocative-holy-yard.bsc.discover.quiknode.pro/mktp-28a590f9bc155bb9b9c86c5773b753b523855717/',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
