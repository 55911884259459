<template>
  <div class="pa-6">
    <div color="transparent" max-width="550px" class="mb-7 fs-20">
      {{ $t('interface.export.desc') }}
    </div>
    <div class="d-flex justify-center mt-5">
      <mew-button
        title="Export"
        btn-size="xlarge"
        class="btn_back w-50"
        :has-full-width="true"
        @click.native="exportConfig"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsExportConfig',
  props: {
    exportConfig: {
      type: Function,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {

  .btn_back.w-50{
    width: 100% !important;
  }
}
</style>
