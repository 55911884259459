<template>
  <div class="mew-component--landing another_univer_home">
    <!--<div class="desktop-content d-none d-lg-none">
      <div
        class="banner-content-container d-flex justify-space-between align-center banner_bg"
      >
        <v-card
          class="logo_left"
          color="transparent"
          flat
          tile
        >
          <div class="white--text mew-title tag_line">
            <span class="tag_line1">
              {{ $t('home.tagline1') | lokalise('home.tagline1') }}
            </span> 
            <span class="another_logo mx-auto">
              <img
                src="../../assets/images/bg/another_logo.png"
                alt="another logo"
              />
            </span>
            <span class="tag_line2">
              {{ $t('home.tagline2') | lokalise('home.tagline2') }}
            </span>
          </div>
          <div class="sub_heading">
            <p class="subheading_title white--text mt-3">
              {{ $t('home.subheading') | lokalise('home.subheading') }}
            </p>
          </div>
          <div class="mt-9 d-flex btn_create_access">
            <button class="btn_create_bg" @click="openSoftwareModule">
              <span class="btn_create">Create a new wallet</span>
            </button>
            <button class="btn_access_bg" @click="openAccessModule">
              <span class="btn_access">Access my wallet</span>
            </button>
          </div>
        </v-card>
        <div class="img_bg">
          <img
            src="../../assets/images/bg/img_bg.png"
            alt="apolo"
            width="90%"
            height="90%"
          />
        </div>
      </div>
    </div> -->

    <!-- <the-default-header /> -->

    <div class="mobile-content">
      <v-container class="d-flex align-center justify-end bg_img">
        <div class="img_astro">
          <img
            src="../../assets/images/bg/removal.png"
            alt="img"
            width="100%"
          />
        </div>

        <v-card color="transparent" flat tile class="width_content">
          <div class="white--text font-weight-bold mb-4">
            <!-- <span>
              {{ $t('home.tagline1') | lokalise('home.tagline2') }}
            </span> -->
            <span class="another_logo_mobile">
              <img
                src="../../assets/images/bg/another_logo_mobile.png"
                alt="another logo"
                width="100%"
              />
            </span>
          </div>
          <div class="tagline_mobile pt-15 pb-10">
            <span class="tagline2_mobile">
              {{ $t('home.tagline2') | lokalise('home.tagline2') }}
            </span>
            <span class="subheading_title__mobile white--text px-10">
              {{ $t('home.subheading') | lokalise('home.subheading') }}
            </span>
          </div>
          <div class="btn_access_create__mobile">
            <button class="btn_access_bg_mobile mb-3" @click="openAccessModule">
              <span class="btn_access_mobile">{{
                $t('home.get-started.button-text-two')
                  | lokalise('home.get-started.button-text-two')
              }}</span>
            </button>
            <button
              class="btn_create_bg_mobile mb-3"
              @click="openSoftwareModule"
            >
              <span class="btn_create_mobile">{{
                $t('home.get-started.button-text-one')
                  | lokalise('home.get-started.button-text-one')
              }}</span>
            </button>
            <!-- <button class="btn_create_bg_mobile">
              <a
                href="au_wallet_guide.pdf"
                target="_blank"
                class="btn_create_mobile"
                style="color: white"
                >{{
                  $t('home.get-started.button-text-guide')
                    | lokalise('home.get-started.button-text-guide')
                }}</a
              >
            </button> -->
            <button class="btn_create_bg_mobile mb-3" @click="openGuide">
              <span class="btn_create_mobile">{{
                $t('home.get-started.button-text-guide')
                  | lokalise('home.get-started.button-text-guide')
              }}</span>
            </button>
            <!-- <button class="btn_create_bg_mobile mb-3" @click="openGuide">
              <span class="btn_create_mobile">Guide</span>
            </button> -->
          </div>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import { ROUTES_HOME } from '@/core/configs/configRoutes';
import handlerAnalytics from '@/modules/analytics-opt-in/handlers/handlerAnalytics.mixin';
// import TheDefaultHeader from '../../views/components-default/TheDefaultHeader.vue';

export default {
  name: 'HomeLanding',
  // components: { TheDefaultHeader },
  mixins: [handlerAnalytics],
  data() {
    return { ROUTES_HOME: ROUTES_HOME };
  },
  mounted() {
    setTimeout(() => {
      this.trackLandingPage();
    }, 1000);
  },
  methods: {
    openSoftwareModule() {
      try {
        this.$router.push({
          name: ROUTES_HOME.CREATE_WALLET.NAME,
          params: { overlay: 'software' },
          query: { type: 'overview' }
        });
      } catch (e) {
        Toast(e, {}, ERROR);
      }
    },
    openGuide() {
      try {
        this.$router.push({
          name: ROUTES_HOME.AU_WALLET_GUIDE.NAME
        });
      } catch (e) {
        Toast(e, {}, ERROR);
      }
    },
    closeSoftwareModule() {
      try {
        this.$router.push({
          name: ROUTES_HOME.CREATE_WALLET.NAME
        });
      } catch (e) {
        Toast(e, {}, ERROR);
      }
    },
    openAccessModule() {
      try {
        this.$router.push({
          name: ROUTES_HOME.ACCESS_WALLET.NAME,
          params: { overlay: 'software' },
          query: { type: 'overview' }
        });
      } catch (e) {
        Toast(e, {}, ERROR);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sub_heading {
  width: 631px;
  text-align: center;
}
.subheading_title {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 500;
  font-size: 30px !important;
  line-height: 150%;
  max-width: 573px;
  max-height: 135px;
  margin: auto auto;
}
.tag_line {
  display: flex;
  flex-direction: column;
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 700;
  text-shadow: 0px 0px 50px rgba(0, 255, 255, 0.5);
}
.tag_line1 {
  font-size: 60px;
  line-height: 78px;
  width: 495px;
  color: #39aaff;
}
.tag_line2 {
  font-size: 40px;
  line-height: 52px;
  color: #39aaff;
  margin: 0 auto;
}
/*.banner_bg {
  position: absolute; 
 top: 0;
  left: 48%;
  transform: translate(-50%, 15%); 
  width: 100%;
}*/
.btn_create_access {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn_create_bg {
  background-image: url(../../assets/images/bg/bg_create.png);
  background-size: 100% 100%;
  background-position: center;
  width: 297px;
  height: 86px;
}
.btn_create {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
  color: #00ffff;
  text-shadow: 0px 0px 50px rgba(0, 255, 255, 0.5);
}
.btn_access_bg {
  background-image: url(../../assets/images/bg/bg_access.png);
  background-size: 100% 100%;
  background-position: center;
  width: 297px;
  height: 86px;
}
.btn_access {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
  color: #ffffff;
  text-shadow: 0px 0px 50px rgba(0, 255, 255, 0.5);
}
.another_logo {
  max-width: 574px;
  max-height: 247px;
  display: block;
  margin: 0 auto;
}
.another_logo img {
  width: 100%;
}
.logo_left {
  width: 32%;
}

.bg_img {
  background-image: url(../../assets/images/bg/bg_dashboard.png);
  background-size: 90% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5rem;
}

.width_content {
  width: 55%;
  padding: 30px 150px 25px 0;
}

//mobile
.another_logo_mobile {
  /* max-width: 259px;
  max-height: 113px; */
  display: block;
  margin: 0 auto;
  width: 85%;
}
.tagline_mobile {
  background-image: url(../../assets/images/bg/destop.png);
  background-size: 100% 100%;
  background-position: center;
  /* width: 335px; */
  /* height: 245px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.tagline2_mobile {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 700;
  /* font-size: 20px; */
  text-align: center;
  font-size: 30px;
  line-height: 26px;
  color: #00ffff;
  text-shadow: 0px 0px 50px rgba(0, 255, 255, 0.5);
  /* margin-top: 120px; */
}
.subheading_title__mobile {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 500;
  /* font-size: 13px; */
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
  width: 95%;
}
.btn_access_create__mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-top: 48px;
}
.btn_create_bg_mobile {
  background-image: url(../../assets/images/bg/bg_create_mobile.png);
  background-size: 100% 100%;
  background-position: center;
  /* width: 250px;
  height: 57.21px; */
  width: 85%;
  height: 80px;
}
.btn_access_bg_mobile {
  background-image: url(../../assets/images/bg/bg_access_mobile.png);
  background-size: 100% 100%;
  background-position: center;
  /* width: 250px;
  height: 57.21px; */
  width: 85%;
  height: 80px;
}
.btn_access_mobile {
  text-shadow: 1px 1px 11px rgba(255, 255, 255, 0.52);
}

.img_astro {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-25%, -50%);
  width: 70%;
}

@media (max-width: 1536px) {
  .bg_img {
    transform: scale(0.8);
    margin-top: 2rem;
  }
}

@media (max-width: 1366px) {
  .bg_img {
    transform: scale(0.7);
    margin-top: 1rem;
  }
}
@media (max-width: 896px) {
  .width_content {
    padding: 80px 65px 60px 0;
  }
}

@media (max-width: 864px) {
  .bg_img {
    background: none !important;
  }
  .width_content {
    width: 100%;
    padding: 0 0 0 0;
  }

  .tagline_mobile {
    background-image: url(../../assets/images/bg/mobile.png);
    height: 700px;
  }
  .img_astro {
    display: none;
  }
  .tagline2_mobile {
    font-size: 55px;
    margin-top: 25rem;
  }
  .subheading_title__mobile {
    font-size: 45px;
    margin-top: 40px;
    width: 90%;
  }

  .btn_access_bg_mobile,
  .btn_create_bg_mobile {
    height: 180px;
  }
  .btn_access_create__mobile {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .tagline_mobile {
    height: 650px;
  }
  .tagline2_mobile {
    margin-top: 22rem;
  }
  .subheading_title__mobile {
    font-size: 30px;
    width: 100%;
  }
  .btn_access_create__mobile {
    font-size: 38px;
    margin-top: 25px;
  }
  .btn_access_bg_mobile,
  .btn_create_bg_mobile {
    height: 140px;
  }
}

@media (max-width: 414px) {
  .bg_img {
    margin-top: 0rem;
  }
  .tagline_mobile {
    height: 380px;
  }
  .tagline2_mobile {
    margin-top: 11rem;
    font-size: 30px;
  }
  .subheading_title__mobile {
    font-size: 15px;
    margin-top: 10px;
  }
  .btn_access_create__mobile {
    font-size: 20px;
  }
  .btn_access_bg_mobile,
  .btn_create_bg_mobile {
    height: 80px;
  }
}

@media (max-width: 375px) {
  .tagline_mobile {
    height: 280px;
  }
  .tagline2_mobile {
    margin-top: 8rem;
    font-size: 25px;
  }
  .subheading_title__mobile {
    font-size: 12px;
  }
  .btn_access_create__mobile {
    font-size: 15px;
  }
  .btn_access_bg_mobile,
  .btn_create_bg_mobile {
    height: 56px;
  }
}
</style>
