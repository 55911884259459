/* eslint camelcase: 0 */
import common from './common/ko_KR';
import confirmation from './confirmation/ko_KR';
import dappsMaker from './dapps-maker/ko_KR';
import dappsSubmission from './dapps-submission/ko_KR';
import home from './home/ko_KR';
import footer from './footer/ko_KR';
import gettingStarted from './getting-started/ko_KR';
import privacyPolicy from './privacy-policy/ko_KR';
import termsOfService from './terms-of-conditions/ko_KR';
import createWallet from './create-wallet/ko_KR';
import accessWallet from './access-wallet/ko_KR';
import interfaceTranslations from './interface/ko_KR';
import team from './team/ko_KR';
import popover from './popover/ko_KR';
import withoutWallet from './without-wallet/ko_KR';
import errorsGlobal from './errors-global/ko_KR';
import trezorError from './errors-trezor/ko_KR';
import bcvaultError from './errors-bcvault/ko_KR';
import coolWalletError from './errors-coolwallet/ko_KR';
import ledgerError from './errors-ledger/ko_KR';
import keepkeyError from './errors-keepkey/ko_KR';
import bitboxError from './errors-bitbox/ko_KR';
import bitbox02Error from './errors-bitbox02/ko_KR';
import buyHardwareWallet from './other/buy-hardware-wallet/ko_KR';
import convertUnits from './other/convert-units/ko_KR';
import verifyMessage from './other/verify-message/ko_KR';
import welcomeMessage from './welcome-message/ko_KR';
import sendTx from './send-tx/ko_KR';
import swap from './swap/ko_KR';
import contract from './contract/ko_KR';
import ens from './dapps-ens/ko_KR';
import unstoppable from './dapps-unstoppable/ko_KR';
import subDomain from './dapps-sub-domain/ko_KR';
import scheduleTx from './dapps-schedule-tx/ko_KR';
import nftManager from './nft-manager/ko_KR';
import signMessage from './sign-message/ko_KR';
import helpCenter from './help-center/ko_KR';
import notifications from './notifications/ko_KR';
import mewcx from './mew-cx/ko_KR';
import ambrpay from './dapps-ambrpay/ko_KR';
import phishing from './other/phishing/ko_KR';
import aave from './dapps-aave/ko_KR';
import header from './header/ko_KR';
import mcdMaker from './dapps-mcd-maker/ko_KR';
import dappsStaked from './dapps-staked/ko_KR';

const ko_KR = {
  common: common,
  confirmation: confirmation,
  home: home,
  footer: footer,
  gettingStarted: gettingStarted,
  privacyPol: privacyPolicy,
  termsOfService: termsOfService,
  team: team,
  accessWallet: accessWallet,
  createWallet: createWallet,
  interface: interfaceTranslations,
  popover: popover,
  withoutWallet: withoutWallet,
  errorsGlobal: errorsGlobal,
  trezorError: trezorError,
  coolWalletError: coolWalletError,
  ledgerError: ledgerError,
  bcvaultError: bcvaultError,
  keepkeyError: keepkeyError,
  bitboxError: bitboxError,
  bitbox02Error: bitbox02Error,
  buyHardwareWallet: buyHardwareWallet,
  dappsMaker: dappsMaker,
  convertUnits: convertUnits,
  verifyMessage: verifyMessage,
  welcomeMessage: welcomeMessage,
  sendTx: sendTx,
  swap: swap,
  contract: contract,
  ens: ens,
  unstoppable: unstoppable,
  subDomain: subDomain,
  scheduleTx: scheduleTx,
  nftManager: nftManager,
  signMessage: signMessage,
  dappsSubmission: dappsSubmission,
  helpCenter: helpCenter,
  notifications: notifications,
  mewcx: mewcx,
  ambrpay: ambrpay,
  phishing: phishing,
  aave: aave,
  header: header,
  mcdMaker: mcdMaker,
  dappsStaked: dappsStaked
};
export default ko_KR;
