<template>
  <div class="default-header">
    <div class="d-flex align-center pt-6">
      <v-row class="logo_bg" align="center" no-gutters>
        <div class="logo_img">
          <router-link :to="{ name: ROUTES_HOME.HOME.NAME, query: {} }">
            <v-img
              :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''"
              src="@/assets/images/icons/logo/logo.png"
              width="100%"
              style="object-fit: cover"
            />
          </router-link>
        </div>
        <!-- <div class="multi_language" style="max-width: 150px">
          <v-select
            v-model="onLanguage"
            append-icon="mdi-chevron-down"
            :items="languages"
            item-text="name"
            item-value="value"
            return-object
            single-line
          >
          </v-select>
        </div> -->

        <!-- Tân custom multi language -->
        <div class="multi_language" style="max-width: 150px">
          <v-select
            :items="languages"
            v-model="onLanguage"
            append-icon="mdi-chevron-down"
            item-text="name"
            item-value="value"
            return-object
            single-line
            class="select_language"
          >
            <template v-slot:selection="{ item }">
              <img :src="item.flag" class="img_language" />{{ item.name }}
            </template>
            <template v-slot:item="{ item }">
              <img :src="item.flag" class="img_language" />{{ item.name }}
            </template>
          </v-select>
        </div>
        <!-- <v-col class="d-md-none" cols="4">
          <the-default-mobile-navigation class="ml-n2" />
        </v-col> -->
        <!-- <v-col class="justify-space-between d-none d-md-flex" cols="4">
          <router-link
            class="white--text text-decoration--none"
            :to="{ name: ROUTES_HOME.HOW_IT_WORKS.NAME }"
          > -->
        <!-- {{ $t('header.what-is-mew') }} -->
        <!-- </router-link> -->
        <!-- <mew-menu
            activator-text-color="white--text"
            :list-obj="menuObj"
            @goToPage="routeTo"
          /> -->
        <!-- <a class="white--text text-decoration--none" @click="openMoonpay">
            {{ $t('header.buy-eth') }}
          </a> -->
        <!-- </v-col> -->
        <!-- <v-col cols="4" class="text-right">
          <mew-tools class="ml-auto" />
        </v-col> -->
      </v-row>
    </div>
  </div>
</template>

<script>
import mewTools from '@/components/mew-tools/MewTools';
// import TheDefaultMobileNavigation from './TheDefaultMobileNavigation';
import { ROUTES_HOME, ROUTES_WALLET } from '@/core/configs/configRoutes';
import { mapGetters } from 'vuex';
import buyMore from '@/core/mixins/buyMore.mixin.js';
import { loadLanguageAsync } from '@/main/i18n';
export default {
  name: 'TheDefaultHeader',
  components: { mewTools },
  // components: { mewTools, TheDefaultMobileNavigation },
  mixins: [buyMore],
  data: () => ({
    onLanguage: null,
    languages: [
      {
        name: 'English',
        value: 'en_US',
        flag: require('@/assets/images/flags/uk.png')
      },
      {
        name: 'Russian',
        value: 'ru_RU',
        flag: require('@/assets/images/flags/russia.png')
      },
      {
        name: 'Japan',
        value: 'ja_JP',
        flag: require('@/assets/images/flags/japan.png')
      },
      {
        name: 'Chinese',
        value: 'zh_CN',
        flag: require('@/assets/images/flags/china.png')
      },
      {
        name: 'Korea',
        value: 'ko_KR',
        flag: require('@/assets/images/flags/korea.png')
      }
    ],
    menuObj: {
      name: 'Wallet actions',
      items: [
        {
          title: 'Popular actions',
          items: [
            {
              title: 'Send transaction',
              to: { name: ROUTES_WALLET.SEND_TX.NAME }
            },
            {
              title: 'Explore DApps',
              to: { name: ROUTES_WALLET.DAPPS.NAME }
            },
            {
              title: 'Swap tokens',
              to: { name: ROUTES_WALLET.SWAP.NAME }
            },
            {
              title: 'Sign message',
              to: { name: ROUTES_WALLET.SIGN_MESSAGE.NAME }
            }
          ]
        },
        {
          title: 'More actions',
          items: [
            {
              title: 'Verify message',
              to: { name: ROUTES_HOME.TOOLS.NAME, query: { tool: 'verify' } }
            },
            {
              title: 'Convert units',
              to: { name: ROUTES_HOME.TOOLS.NAME, query: { tool: 'convert' } }
            }
          ]
        }
      ]
    },
    ROUTES_HOME: ROUTES_HOME
  }),
  watch: {
    onLanguage({ value }) {
      loadLanguageAsync(value);
    }
  },
  mounted() {
    this.onLanguage = this.languages[0].value;
  },
  computed: {
    ...mapGetters('global', ['swapLink'])
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    }
  }
};
</script>

<style lang="scss" scoped>
.logo_bg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 8%;
  transform: translateX(-50%);
  z-index: 99;
}
.logo_img {
  align-items: flex-start;
  transform: scale(1.5);
  /* justify-content: space-around; */
  /* position: absolute;
  top: 0;
  left: 0;
  width: 5%;
  transform: translate(200%,50%); */
}

.img_language {
  width: 30%;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .logo_bg {
    top: 3%;
  }
  .logo_img {
    width: 15%;
    transform: translate(20%, 10%);
  }
}
</style>
