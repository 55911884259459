var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center container dashboard_desktop pa-5"},[_c('div',{staticClass:"dashboard"},[[_c('div',{staticClass:"dashboard__logo"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('router-link',{attrs:{"to":{ name: _vm.ROUTES_WALLET.DASHBOARD.NAME }}},[_c('img',{attrs:{"width":"63","src":require("@/assets/images/icons/logo/logo.png")}})]),_c('div',{staticClass:"multi_language",staticStyle:{"max-width":"150px"}},[_c('v-select',{staticClass:"select_language",attrs:{"items":_vm.languages,"append-icon":"mdi-chevron-down","item-text":"name","item-value":"value","return-object":"","single-line":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"img_language",attrs:{"src":item.flag}}),_vm._v(_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"img_language",attrs:{"src":item.flag}}),_vm._v(_vm._s(item.name)+" ")]}}]),model:{value:(_vm.onLanguage),callback:function ($$v) {_vm.onLanguage=$$v},expression:"onLanguage"}})],1)],1),_c('balance-card')],1)],_c('v-list',{staticClass:"px-5 buy_send_swap"},[_c('v-list-item-group',[_c('div',{staticClass:"d-flex align-center buy_send_swap_border"},[_c('v-list-item',{staticClass:"px-0",attrs:{"active-class":"remove-select-state"},on:{"click":_vm.openMoonpay}},[_c('div',{staticClass:"text-center mx-auto my-2 button_buy_send_swap"},[_c('div',{staticClass:"img_buy_sell"},[_c('img',{attrs:{"src":require("../../assets/images/dashboard/down.png"),"alt":"Buy or Sell","height":"32"}})]),_c('div',{staticClass:"btn-title"},[_vm._v(_vm._s(_vm.$t('common.buy_sell')))])])]),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-list-item',{staticClass:"px-0",attrs:{"to":{ name: _vm.ROUTES_WALLET.SEND_TX.NAME }}},[_c('div',{staticClass:"text-center mx-auto my-2 button_buy_send_swap"},[_c('div',{staticClass:"img_buy_sell"},[_c('img',{attrs:{"src":require("../..//assets/images/dashboard/send.png"),"alt":"Send","height":"32"}})]),_c('div',{staticClass:"btn-title"},[_vm._v(_vm._s(_vm.$t('common.send')))])])]),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-list-item',{staticClass:"px-0",class:[!_vm.hasSwap ? 'opacity--30 pointer-event--none' : ''],on:{"click":_vm.swap_pending}},[_c('div',{staticClass:"text-center mx-auto my-2 button_buy_send_swap"},[_c('div',{staticClass:"img_buy_sell"},[_c('img',{attrs:{"src":require("../../assets/images/dashboard/swap.png"),"alt":"Swap","height":"32"}})]),_c('div',{staticClass:"btn-title"},[_vm._v(_vm._s(_vm.$t('common.swap')))])])])],1)])],1)],2),_c('mew-popup',{attrs:{"max-width":"400px","hide-close-btn":"","show":_vm.showLogoutPopup,"title":_vm.$t('interface.menu.logout'),"left-btn":{
      text: _vm.$t('interface.cancel'),
      method: _vm.toggleLogout,
      color: 'basic'
    },"right-btn":{
      text: _vm.$t('interface.logout'),
      color: 'error',
      method: _vm.onLogout,
      enabled: true
    }}}),_c('module-settings',{attrs:{"on-settings":_vm.onSettings},on:{"closeSettings":_vm.closeSettings}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }