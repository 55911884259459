<template>
  <div class="mew-component--create--mnemonic-phrase" style="width: 100%">
    <mew-stepper
      :items="items"
      :on-step="step"
      class="mx-md-0"
      style="margin: 0 auto"
    >
      <!--
      =====================================================================================
        Step 1: Create Password
      access__wallet__mobile 
      =====================================================================================
      -->
      <template v-if="step === 1" #stepperContent1>
        <div class="d-flex align-center justify-space-between">
          <!-- <div class="d-flex align-start img_right">
            <img
              src="@/assets/images/create/iouiouiouio.png"
              alt="Keystore File"
              width="100%"
            />
          </div> -->

          <div class="step3_create">
            <div class="font-weight-bold text_primary step_title">
              {{ $t('home.step_one') }}
            </div>
            <div class="font-weight-bold mb-5 create_step__title">
              {{ $t('createWallet.keystore.create_password') }}
            </div>
            <!--
          =====================================================================================
            Enter Password
          =====================================================================================
          -->
            <mew-input
              v-model="password"
              :hint="hint1"
              :placeholder="password1"
              :has-clear-btn="true"
              class="flex-grow-1 mb-2 input_bg fst_normal"
              :rules="passwordRulles"
              type="password"
              :label="$t('createWallet.keystore.password1')"
            />
            <!--
          =====================================================================================
            Confirm Password
          =====================================================================================
          -->
            <mew-input
              v-model="cofirmPassword"
              hint=""
              :placeholder="confirmPasswordStep1"
              class="flex-grow-1 input_bg"
              :rules="passwordConfirmRulles"
              type="password"
             :label="$t('createWallet.keystore.confirm_password1')"
            />
            <!--
          =====================================================================================
            Creat Wallet Button
          =====================================================================================
          -->
            <div
              v-if="!isGeneratingKeystore"
              class="d-flex justify-center create_wallet__keystore"
            >
              <mew-button
                :title="titleStep1"
                btn-size="xlarge"
                :has-full-width="false"
                :disabled="!enableCreateButton"
                @click.native="createWallet"
                class="btn_select btn_width"
              />
            </div>
            <!--
          =====================================================================================
            Loading State: isGeneratingKeystore = true
          =====================================================================================
          -->
            <v-row v-else justify="center" align="center">
              <v-progress-circular
                indeterminate
                color="greenPrimary"
              ></v-progress-circular>
              <p class="mb-0 mx-3 fst-normal">
                {{ $t('createWallet.keystore.loading_create_wallet') }}
              </p>
            </v-row>
            <!--
        =====================================================================================
          Warning Block
        =====================================================================================
        -->
            <!-- <mew-warning-sheet
        class="mt-4 mb-0"
        title="NOT RECOMMENDED"
        description="This information is sensitive, and these options should only be used in offline settings by experienced crypto users. You will need your keystore file + password to access your wallet. Please save them in a secure location. We CAN NOT retrieve or reset your keystore/password if you lose them."
      /> -->
          </div>
        </div>
      </template>
      <!--
      =====================================================================================
        Step 2: Download Keystore
      =====================================================================================
      -->
      <template v-if="step === 2" #stepperContent2>
        <div class="step_2_wallet">
          <div class="font-weight-bold text_primary step_title">
            {{ $t('home.step_two') }}
          </div>
          <div class="font-weight-bold create_step__title">
            {{ $t('createWallet.keystore.download_keystore_file') }}
          </div>
          <div class="text_des">
            {{ $t('createWallet.keystore.important_things') }}
          </div>
          <v-row class="align-stretch">
            <v-col v-for="(d, key) in warningData" :key="key" cols="12" sm="4">
              <div class="px-8 py-5 border-container">
                <div class="d-flex justify-center py-3">
                  <mew-icon :icon-name="d.icon" :img-height="70" />
                </div>
                <h5 class="font-weight-bold h5_text mt-1 mb-2">
                  {{ d.title }}
                </h5>
                <div class="des_text">{{ d.description }}</div>
              </div>
            </v-col>
          </v-row>
          <div class="d-flex flex-column flex-md-row justify-center mt-6">
            <mew-button
              :title="titleBack"
              btn-size="xlarge"
              :has-full-width="false"
              class="me-md-4 my-1 px-10 btn_veri btn_back"
              @click.native="updateStep(1)"
            />
            <mew-button
              :title="titleStep2"
              btn-size="xlarge"
              :has-full-width="false"
              class="ms-md-4 my-1 px-10 btn_veri btn_select"
              @click.native="downloadWallet"
            />
          </div>
          <a
            ref="downloadLink"
            :href="walletFile"
            rel="noopener noreferrer"
            :download="name"
            class="link"
          />
        </div>
        <!-- <mew-warning-sheet
        class="mt-4 mb-0"
        title="NOT RECOMMENDED"
        description="This information is sensitive, and these options should only be used in offline settings by experienced crypto users. You will need your keystore file + password to access your wallet. Please save them in a secure location. We CAN NOT retrieve or reset your keystore/password if you lose them."
      /> -->
      </template>
      <!--
      =====================================================================================
        Step 3: Done
      =====================================================================================
      -->
      <template v-if="step === 3" #stepperContent3>
        <div class="step_3_wallet">
          <div class="d-flex align-center">
            <div class="step3_create">
              <div class="font-weight-bold text_primary step_title">
                {{ $t('home.step_three') }}
              </div>
              <div class="font-weight-bold mb-3 create_step__title">
                {{ $t('createWallet.keystore.you_are_done') }}
              </div>
              <p class="text_des mb-8">
                {{ $t('createWallet.keystore.you_are_dont_desc') }}
              </p>

              <div class="d-flex justify-center flex-column">
                <mew-button
                  :title="titleAccessStep3"
                  btn-size="xlarge"
                  :has-full-width="false"
                  class="mb-3 btn_select"
                  @click.native="
                    $router.push({
                      path: '/wallet/access/software',
                      query: { type: 'overview' }
                    })
                  "
                />
                <mew-button
                  :title="titleCreateAnotherStep3"
                  :has-full-width="false"
                  btn-size="xlarge"
                  class="btn_create btn_no_border"
                  @click.native="updateStep(1)"
                />
              </div>
            </div>
            <v-img
              class="ml-8 img_plane"
              max-width="400px"
              max-height="550px"
              src="@/assets/images/create/keystore.png"
            />
          </div>
        </div>
      </template>
    </mew-stepper>
  </div>
</template>

<script>
import { Toast, ERROR } from '@/modules/toast/handler/handlerToast';
import { ROUTES_HOME } from '@/core/configs/configRoutes';
import handlerAnalytics from '@/modules/analytics-opt-in/handlers/handlerAnalytics.mixin';
import WALLET_TYPES from '@/modules/access-wallet/common/walletTypes';

export default {
  name: 'CreateWalletKeystore',
  mixins: [handlerAnalytics],
  props: {
    handlerCreateWallet: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      password1: this.$t('createWallet.keystore.password1'),
      confirmPasswordStep1: this.$t('createWallet.keystore.confirm_password1'),
      hint1: this.$t('createWallet.keystore.hint_password1'),
      titleStep1: this.$t('createWallet.keystore.create_wallet'),
      titleStep2: this.$t('createWallet.keystore.btn_acknowledge_download'),
      titleBack: this.$t('createWallet.keystore.back'),
      titleAccessStep3: this.$t('createWallet.keystore.btn_access'),
      titleCreateAnotherStep3: this.$t(
        'createWallet.keystore.btn_create_another'
      ),
      step: 1,
      warningData: [
        {
          icon: 'paperPlane',
          title: this.$t('createWallet.keystore.loose.title'),
          description: this.$t('createWallet.keystore.loose.desc')
        },
        {
          icon: 'thief',
          title: this.$t('createWallet.keystore.share.title'),
          description: this.$t('createWallet.keystore.share.desc')
        },
        {
          icon: 'copy',
          title: this.$t('createWallet.keystore.backup.title'),
          description: this.$t('createWallet.keystore.backup.desc')
        }
      ],
      items: [
        {
          step: 1
        },
        {
          step: 2
        },
        {
          step: 3
        }
      ],
      password: '',
      cofirmPassword: '',
      passwordRulles: [
        value => !!value || this.$t('createWallet.keystore.required'),
        value =>
          value.length >= 7 || this.$t('createWallet.keystore.password_length')
      ],

      walletFile: '',
      name: '',
      isGeneratingKeystore: false
    };
  },
  computed: {
    enableCreateButton() {
      return (
        this.password !== '' &&
        this.cofirmPassword === this.password &&
        this.password.length >= 7
      );
    },
    passwordConfirmRulles() {
      return [
        value => !!value || this.$t('createWallet.keystore.required'),
        value =>
          value === this.password ||
          this.$t('createWallet.keystore.password_math')
      ];
    }
  },
  methods: {
    createWallet() {
      this.isGeneratingKeystore = true;
      this.handlerCreateWallet
        .generateKeystore(this.password)
        .then(res => {
          this.name = res.name;
          this.walletFile = res.blobUrl;
          this.updateStep(2);
          this.isGeneratingKeystore = false;
        })
        .catch(e => {
          Toast(e, {}, ERROR);
        });
    },
    downloadWallet() {
      this.$refs.downloadLink.click();
      this.trackCreateWallet(WALLET_TYPES.KEYSTORE);
      this.updateStep(3);
    },
    goToAccess() {
      this.$router.push({ name: ROUTES_HOME.ACCESS_WALLET.NAME });
    },
    /**
     * Update step
     */
    updateStep(step) {
      this.step = step ? step : 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.mew-component--create--mnemonic-phrase .v-input--radio-group__input {
  flex-wrap: nowrap !important;
}

.mew-component--create--mnemonic-phrase .mew-stepper.v-stepper {
  background: transparent !important;
  width: 80% !important;
}

.link {
  opacity: 0;
  position: absolute;
  top: 100000px;
  z-index: -1;
}
.mew-component--keystore .mew-stepper.v-stepper {
  background: transparent !important;
}
.border-container {
  background: rgba(0, 255, 255, 0.3);
  /* border: 1px solid var(--v-greenPrimary-base); */
  border-radius: 7px;
  /* box-shadow: 0 8px 15px var(--v-greyLight-base); */
  height: 100%;
}
.btn_veri {
  width: 50% !important;
}
.h5_text {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .btn_back {
    display: none !important;
  }
  .btn_veri {
    width: 100% !important;
  }
}
</style>
